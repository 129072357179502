import { createBrowserRouter } from "react-router-dom";
import MainContainer from "./layouts/MainContainer/MainContainer";
import AskAnyAi from "./pages/AskAnyAi/AskAnyAi";
import CodeTranslate from "./pages/CodeTranslate/CodeTranslate";
import Dashboard from "./pages/Dashboard/Dashboard";
import Pool from "./pages/Pool/Pool";
// import Launchpad from "./pages/Launchpad/Launchpad";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MainContainer mainContainer={true}>
        <AskAnyAi />
      </MainContainer>
    ),
  },
  // {
  //   path: "/dashboard",
  //   element: <Dashboard />,
  // },
  // {
  //   path: "/farm",
  //   element: <Pool />,
  // },
  // {
  //   path: "/code-translate",
  //   element: <CodeTranslate />
  // },
  // {
  //   path: "/launchpad",
  //   element: (
  //     <MainContainer>
  //       <Launchpad />
  //     </MainContainer>
  //   ),
  // },
]);
