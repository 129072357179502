import { createTheme, useTheme, responsiveFontSizes } from "@mui/material/styles";
import { createMakeStyles } from "tss-react";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    "contained-custom": true;
    "outlined-custom": true;
    "contained-not-selected": true;
  }
}

const primaryColor = "#ffffff";
const secondaryColor = "#00FFA3";
const textPrimary = "#ffffff";
const BgSecondary = "rgb(17 17 17 / 47%)";
const textSecondary = "#000000";

let theme = createTheme({
  palette: {
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    background: {
      default: BgSecondary
    }
  },
  typography: {
    fontFamily: "bakbakOne",
    fontSize: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {},
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            textTransform: "none",
          },
        },
        {
          props: { variant: "contained-not-selected" },
          style: {
            color: secondaryColor,

            backgroundColor: primaryColor,
            textTransform: "none",
            borderRadius: "0px",
            padding: "5px 20px",
            // border: "2px solid white",
            maxWidth: "170px",

            "&:hover": {
              // background: "linear-gradient(to right, #FCCA03, #ffb813)",
              // opacity: 0.8,
              background: "rgba(0, 255, 163, 0.8)",
            },
          },
        },
        {
          props: { variant: "contained-custom" },
          style: {
            color: textSecondary,

            backgroundColor: "#00FFA3",
            textTransform: "none",
            borderRadius: "0px",
            padding: "5px 20px",
            // border: "2px solid white",
            maxWidth: "170px",

            "&:hover": {
              // background: "linear-gradient(to right, #FCCA03, #ffb813)",
              // opacity: 0.8,
              background: "rgba(0, 255, 163, 0.8)",
            },
          },
        },
        {
          props: { variant: "outlined-custom" },
          style: {
            color: "#00FFA3",
            // backgroundImage: "linear-gradient(to right, #FCCA03, #ffb813)",
            background: "transparent",

            // background: "#030303",
            padding: "5px 20px",
            textTransform: "none",
            border: "1px solid #00FFA3",
            // border: "2px solid",
            // borderImageSlice: 1,

            "&:disabled": {
              color: "rgba(255, 255, 255, 0.4)",
            },

            "&:hover": {
              opacity: 0.8,

              // background: "#030303",
              // borderImageSource: "linear-gradient(to right,  #FCCA03, #ffb813)",
            },
          },
        },
      ],
    },

    MuiTextField: {
      styleOverrides: {
        root: {

        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottom: "1px solid #ffffff !important",

          }
        }
      }
    }
  },
});

theme = responsiveFontSizes(theme);

const { makeStyles } = createMakeStyles({
  useTheme: useTheme as () => typeof theme,
});

export { theme, makeStyles };
