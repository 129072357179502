import { makeStyles } from "../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    minWidth: "250px",
    border: `1px solid ${theme.palette.secondary.main}`,
    // borderRadius: "25px",
    padding: "10px 20px",
    gap: "10%",
    alignItems: "center",
    backgroundColor: "#282828c1",
    display: "flex",

    [theme.breakpoints.down("md")]: {
      minWidth: "calc(90vw - 80px)",
    },


    [theme.breakpoints.down("sm")]: {
      minWidth: "calc(80vw - 80px)",
    },
  },
}));
