import { multicall, prepareWriteContract, readContract, writeContract } from "@wagmi/core";
import { BigNumberish, ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { useAccount, useContractRead } from "wagmi";
import { contractConfigs } from "../contracts";
import { mockLpTokenAbi } from "../contracts/abis/mockLpTokenAbi";
import { ContractNames } from "../utils";
import { useConnectWallet } from "./useConnectWallet";
import { useStakingContract } from "./useContracts";

export const useStaking = () => {
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();

  const { stakingContract } = useStakingContract();

  const depositTokens = async (poolId: number, value: string, stakingContractAddress: `0x${string}`) => {
    try {
      setLoading(true);

      const allowanceAmount = await checkAllowance(stakingContractAddress);

      const allowanceAmountFormat = Number(ethers.utils.formatEther(allowanceAmount as BigNumberish).toString());

      if (Number(value) > allowanceAmountFormat || Number(allowanceAmountFormat) === 0) {
        await approveAllowance(stakingContractAddress);
      }

      const txn = await stakingContract?.deposit(poolId, ethers.utils.parseEther(value), address);
      await txn.wait();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const harvestTokens = async (poolId: number, toAddress: string) => {
    try {
      setLoading(true);
      const txn = await stakingContract?.harvest(poolId, toAddress);

      await txn.wait();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const withdrawTokens = async (poolId: number, value: string) => {
    try {
      setLoading(true);

      const txn = await stakingContract?.withdraw(poolId, ethers.utils.parseEther(value), address);

      await txn.wait();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const approveAllowance = async (stakingContractAddress: `0x${string}`) => {
    const config = await prepareWriteContract({
      address: stakingContractAddress,
      abi: mockLpTokenAbi,
      functionName: "approve",
      args: [contractConfigs.Staking.address, ethers.constants.MaxUint256],
    });

    const txn = await writeContract(config);

    await txn.wait();
  };

  const checkAllowance = async (stakingContractAddress: `0x${string}`) => {
    const data = await readContract({
      address: stakingContractAddress,
      abi: mockLpTokenAbi,
      functionName: "allowance",
      args: [address, contractConfigs.Staking.address],
    });

    return data;

    // const data = await mockLpTokenContract?.allowance(
    //   address,
    //   contractConfigs.Staking.address
    // );
    // return data;
  };

  return {
    depositTokens,
    withdrawTokens,
    harvestTokens,
    loading,
  };
};

export const useMulticall = () => {
  const { address, isConnected } = useConnectWallet();
  const { data: poolLen } = useContractRead({
    ...contractConfigs[ContractNames.StakingContract],
    functionName: "poolLength",
  });
  const [balances, setBalances] = useState<any[]>();
  const [stakedTokenContractAddresses, setStakedTokenContractAddresses] = useState<`0x${string}`[]>();

  const getPoolsInfo = useCallback(async (poolLength: number) => {
    let contractsInfo = [];
    let stakedTokenContractAddresses: `0x${string}`[] = [];

    for (let i = 0; i < poolLength; i++) {
      const info = {
        ...contractConfigs.Staking,
        functionName: "poolInfo",
        args: [i],
      };

      contractsInfo.push(info);
    }

    const poolInfo = await multicall({
      contracts: [...contractsInfo],
    });

    poolInfo.forEach((pool) => {
      stakedTokenContractAddresses.push(pool.stakedToken as `0x${string}`);
    });

    setStakedTokenContractAddresses(stakedTokenContractAddresses);

    return stakedTokenContractAddresses;
  }, []);

  const contractReadsForPools = useCallback(
    async (poolLen: number, address: `0x${string}` | string | undefined) => {
      const stakedContractAddress = await getPoolsInfo(poolLen);
      const contractReadsConfigs: any[] = [];

      for (let i = 0; i < poolLen; i++) {
        const balanceOf = {
          address: stakedContractAddress[i],
          abi: mockLpTokenAbi,
          functionName: "balanceOf",
          args: [address],
        };

        contractReadsConfigs.push(balanceOf);

        const userInfo = {
          ...contractConfigs.Staking,
          functionName: "userInfo",
          args: [i, address],
        };
        contractReadsConfigs.push(userInfo);

        const pendingReward = {
          ...contractConfigs.Staking,
          functionName: "pendingReward",
          args: [i, address],
        };

        contractReadsConfigs.push(pendingReward);
      }

      const balances = await multicall({
        contracts: [...contractReadsConfigs],
      });

      // const unwatchBalances = watchMulticall(
      //   {
      //     contracts: [...contractReadsConfigs],
      //     listenToBlock: true,
      //   },
      //   (data) => {
      //     setBalances(data);
      //   }
      // );

      return { balances };
    },
    [getPoolsInfo]
  );

  const refetch = async () => {
    const { balances } = await contractReadsForPools(Number(ethers.BigNumber.from(poolLen).toString()), address);

    setBalances(balances);
  };

  useEffect(() => {
    async function getBalances() {
      const { balances } = await contractReadsForPools(Number(ethers.BigNumber.from(poolLen).toString()), address);

      setBalances(balances);
    }

    if (isConnected) {
      getBalances();
    }
  }, [poolLen, address, isConnected, contractReadsForPools]);

  return { balances, refetch, stakedTokenContractAddresses };
};
