import React, { createContext, useContext, useState } from "react";

const initialState = {
  tabValue: 0,
  handleTabChange: (e: React.SyntheticEvent, newValue: number) => {},
  changeTab: (newValue: number) => {},
};
const TabContext = createContext(initialState);

export const useTabContextProvider = () => useContext(TabContext);

export const TabContextProvider = ({ children }: { children: JSX.Element }) => {
  const [tabValue, setTabValue] = useState(initialState.tabValue);

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const changeTab = (newValue: number) => {
    setTabValue(newValue);
  };

  return <TabContext.Provider value={{ tabValue, handleTabChange, changeTab }}>{children}</TabContext.Provider>;
};

export default TabContextProvider;
