import { makeStyles } from "../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    // padding: "5px 30px 30px 30px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
  dashboard: {
    // marginTop: "20px",
    // marginLeft: "80px",
    // padding: "0px 50px 0px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      marginTop: "30px",
    },
  },
  cardWrapper: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
    },
  },
  cardHead: {
    marginTop: "10px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    gap: "20px",
    [theme.breakpoints.down("lg")]: {
      gap: "20px",
    },
  },
  graphWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "20px",
    marginTop: "5%",
    [theme.breakpoints.up("xl")]: {
      marginTop: "3%",
    },
  },
  graphsContainer: {
    position: "relative",
    display: "flex",
    // flexWrap: "wrap",
    alignItems: "center",
    flex: 1,
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  graphContainer: {
    position: "relative",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // minWidth: "600px",
    // minHeight: "450px",
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  cardsContainer: {
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
  },
  lineChart: {
    display: "flex",
    flexDirection: "column",
    marginTop: "128px",
    width: "90%",
    gap: "10px",
    marginLeft: "5%",
    marginRight: "5%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
  imageLink: {
    aspectRatio: 'auto',
    height: '42px',
    objectFit: 'contain',
    margin: '0px 2px',
    width: '9%',
    [theme.breakpoints.down("lg")]: {
      height: "24px",
    },
    [theme.breakpoints.down("md")]: {
      height: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "16px",
    },
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)",
    },
  },
}));
