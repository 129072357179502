import bgWrapper from "../../assets/images/bgWrapper.png";
import { makeStyles } from "../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    // maxWidth: "1400px",

    display: "flex",
    flexDirection: "column",
  },

  bgWrapper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "100%",
    height: "100%",
    background: `url(${bgWrapper})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    // backgroundSize: "cover",
  },
  iconsContainer: {
    padding: "20px 25px",
    // "@media (min-width: 2850px)": {
    //   padding: "20px 25px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   padding: "20px 25px",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   padding: "20px 25px",
    // },
  },
  mainContainer: {
    // width: "60vw",
    // margin: "0px auto",
    // display: "flex",
    // height: "100%",
    // marginTop: "10%",
    // flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      // width: "80vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "5%",
      margin: "0px",
    },
  },
  imgContainer: {
    marginTop: "30px",
  },
  poweredBy: {
    position: "fixed",
    bottom: "0",
    right: "0",
    margin: "20px 30px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "20px",
      fontSize: "16px",
    },
  },
  poweredByImage: {
    marginLeft: "5px",
    height: "28px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
      height: "20px",
    },
  },
}));
