import { makeStyles } from "../../utils/theme";
import { keyframes } from "@mui/system";

const changeOpacity = keyframes`
  0% {
    opacity: 0;
  } 100% {
    opacity: 0.5;
  }
`;

export const useStyles = makeStyles()((theme) => ({

  textToImageWrapper: {
    width: "100%",
    justifyContent: "start",
    alignSelf: "self-start",
    display: "flex",
    flexDirection: "column",
    // padding: "0px 5%",

  },
  root: {


    width: "100%",
    // "@media (min-width: 2200px)": {
    //   maxWidth: "40vw",
    //   justifyContent: "center"
    // }
    [theme.breakpoints.down("md")]: {
      // width: "100%",
      // justifyContent: "center",
      alignSelf: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
  container: {

    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "44px",
    flexDirection: "column",
    // minHeight: "50vh",
    // maxWidth: "1200px",
    margin: "auto",

    // marginTop: "150px",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
    },


  },
  tabs: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    gap: "20px",
    maxWidth: "30vw",
    [theme.breakpoints.down("md")]: {
      gap: "10px",
    },
  },

  boxContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "37%",
    // maxWidth: "1200px",
    // justifyContent: "center",
    // alignItems: "center",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    gap: "34px",
    marginTop: ".5rem",
    // margin: 0,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
  },
  inputFieldContainer: {
    // padding: "10px",
    width: "100%",
    minHeight: "54px",
    "& input": {

      color: "#ffffff !important",
    },

    background: "white",
    // border: `1px solid ${theme.palette.secondary.main}`,
    // borderRight: "0px",
    backgroundColor: "transparent",
    // borderTopLeftRadius: "5px",
    // maxWidth: "1000px",
    // borderRadius: "50px",
    "::placeholder": {
      // opacity: 1,
      fontFamily: "'Inter' !important",

      "@media (min-width: 2200px)": {
        fontSize: "2rem",
        fontWeight: 200
      },
    },
    "::-ms-input-placeholder": {
      color: "#ffffff",
    },
    "@media (min-width: 2200px)": {
      fontSize: "1.5rem",
      justifyContent: "center",
    },
    // borderBottomLeftRadius: "5px",
  },
  inputFieldContainerPrompt: {
    // padding: "10px",
    color: "black",
    width: "80%",
    // justifyContent: "center",
    background: "white",
    // border: `1px solid ${theme.palette.secondary.main}`,
    maxWidth: "1000px",
    // borderRight: "0px",
    backgroundColor: "transparent",
    // borderTopLeftRadius: "5px",
    // borderRadius: "50px",
    "@media (min-width: 2200px)": {
      fontSize: "1.5rem",
      justifyContent: "center",
    },
  },
  inputFieldContainer2: {

    width: "100%",
    marginLeft: "auto",
    // padding: "10px",
    // background: "#f1f1f1",
    // border: `1px solid ${theme.palette.secondary.main}`,
    borderTop: "0px",
    borderRadius: "50px",
    // marginRight: ".5rem",
    display: "flex",
    justifyContent: "center",
    "::placeholder": {
      color: "#ffffff",
      fontFamily: "'Inter' !important",

      // opacity: 1,
      "@media (min-width: 2200px)": {
        fontWeight: 200,
        fontSize: "2rem",
      },
    },
    "::-ms-input-placeholder": {
      color: "#ffffff",
    },
    "@media (min-width: 2200px)": {
      fontSize: "1.5rem",
      justifyContent: "center",
    },
  },

  dialog: {
    // backgroundColor: "transparent",
    height: "100%",
    // overflow: "hidden",
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    // backgroundColor: "#010101",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  backButton: {
    width: "50px",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  // input: {
  //   // fontFamily: "sans-serif",
  //   fontFamily: "bakbakOne",
  //   color: "black ",
  // },

  btn: {
    padding: "5px 5px",
    // borderTopLeftRadius: "15px",
    // borderBottomLeftRadius: "15px",
    // margin: ".5rem 0px",
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      alignSelf: "center",
    },
  },
  btn2: {
    // visibility: "hidden",
    // padding: "0px 44px",
    // maxWidth: "200px",
    // visibility: "hidden",

    padding: "0px 17px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    fontWeight: theme.typography.fontWeightBold,
    // border: "1px solid white",

    "& > .MuiSvgIcon-root": {
      color: "white",
    },

    "&.Mui-focused": {
      border: "none",
    },

    "& > .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white",
    },
    "&.Muifocused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white",
    },
  },

  imagesContainer: {
    position: "relative",
    // margin: "40px auto",
    // width: "100%",
    gap: "5px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  outputImageContainer: {
    position: "relative",
    width: "100%",
    // maxWidth: "512px",
    height: "100%",
    // overflow: "hidden",
  },
  animation: {
    animation: `${changeOpacity} 1s infinite ease-in-out`,
  },
  outputImage: {
    border: `1px solid ${theme.palette.secondary.main}`,
    height: "100%",
    width: "99%",
    transition: "all ease-in-out 0.2s",
  },

  btnsContainer: {
    display: "flex",
    userSelect: "none",
    justifyContent: "center",
    marginTop: "5px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px",
    },
    // top: "90%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
  },

  actionBtn: {
    width: "max-content",
    margin: "0px 2px",
  },
  // variationBtn: {
  //   userSelect: "none",
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  // },
  negativePromptBtn: {
    height: "30px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "none",
    },
  },
  selectTextToImage: {
    border: "#FCCA03 1px solid",
    backgroundColor: "#000000",
    ":hover": {
      backgroundColor: "none",
    },
  },
}));
