import { goerli } from "wagmi";
import { ContractNames } from "../utils";
import { askAnyAiAbi } from "./abis/askAnyAbi";
import { mockLpTokenAbi } from "./abis/mockLpTokenAbi";
import { stakingAbi } from "./abis/stakingAbi";
import { askAnyAbiGoerli } from "./goerliAbis/askAnyAiAbiGoerli";
import { askanyNftGoerliAbi } from "./goerliAbis/askanyNftGoerli";
import { mockLpTokenAbiGoerli } from "./goerliAbis/mockLpTokenAbiGoerli";
import { stakingAbiGoerli } from "./goerliAbis/stakingAbiGoerli";

interface ContractConfigTypes {
  [ContractNames.MockLPToken]: {
    abi: any;
    address: `0x${string}`;
  };
  [ContractNames.AskAnyAi]: {
    abi: any;
    address: `0x${string}`;
  };
  [ContractNames.StakingContract]: {
    abi: any;
    address: `0x${string}`;
  };
  [ContractNames.AskAnyNFT]?: {
    abi: any;
    address: `0x${string}`;
  };
}

export const bsccontractConfigs: ContractConfigTypes = {
  [ContractNames.MockLPToken]: {
    abi: mockLpTokenAbi,
    address: "0x5dD695fCC2eA0cDf1Fe2cD6E33165088006d1D6A",
  },
  [ContractNames.StakingContract]: {
    abi: stakingAbi,
    address: "0x3805D7a0CC2D9056440336d0650b3A5f06D35512",
  },
  [ContractNames.AskAnyAi]: {
    abi: askAnyAiAbi,
    address: "0x99fAb28C0B58014D231c3073Ba559e18E8097F57",
  },
};

export const goerliContractConfigs: ContractConfigTypes = {
  [ContractNames.MockLPToken]: {
    abi: mockLpTokenAbiGoerli,
    address: "0xCA75e4d5cdB37aD0c977Eb521908b49C516d8719",
  },
  [ContractNames.AskAnyAi]: {
    abi: askAnyAbiGoerli,
    address: "0x981aF2dE1240d623B296fd03aed408c6dE89a66c",
  },
  [ContractNames.StakingContract]: {
    abi: stakingAbiGoerli,
    address: "0xF84A43A38269668EF3ec567800958000B4C68FD6",
  },
  [ContractNames.AskAnyNFT]: {
    abi: askanyNftGoerliAbi,
    address: "0xbF765C050225D95BE754C96Fd06125E0274ba656",
  },
};

const addressesByChainId: { [key: string]: ContractConfigTypes } = {
  [goerli.id]: goerliContractConfigs,
  97: bsccontractConfigs,
};

const defaultChainId = goerli.id;

export const contractConfigs = addressesByChainId[defaultChainId];
