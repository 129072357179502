import { makeStyles } from "../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    border: `1px solid ${theme.palette.secondary.main}`,
    // borderRadius: "25px",
    marginTop: "2%",
    backgroundColor: "#282828c1",
    display: "flex",
    flexDirection: "column",

    "@media screen and (max-width: 1800px)": {
      marginTop: "0",
    },
  },
}));
