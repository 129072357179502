import { useState } from "react";
import { BASE_URL } from "../config/apiConfig";

export const useChat = () => {
  const [chatData, setChatData] = useState<
    {
      input: string;
      output?: string;
    }[]
  >([]);
  const [currentChat, setCurrentChat] = useState({
    isFetching: false,
    input: "",
    output: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined | boolean>(undefined);
  const abortController = new AbortController();

  const generateChatResponse = async (prompt: string) => {
    try {
      setLoading(true);

      const res = await fetch(`${BASE_URL}/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt,
        }),
        signal: abortController.signal,
      });

      const reader = res.body!.getReader();

      const decoder = new TextDecoder();
      let done = false;

      setChatData((prevChat) => {
        return [
          ...prevChat,
          {
            input: prompt,
            output: "",
          },
        ];
      });

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);

        setChatData((prevChat) => {
          const lastPrompt = prevChat[prevChat.length - 1];
          if (lastPrompt.input === prompt) {
            return prevChat.map((chat, i) => {
              if (i === prevChat.length - 1) {
                return {
                  ...chat,
                  input: prompt,
                  output: chat.output + chunkValue,
                };
              } else {
                return {
                  ...chat,
                };
              }
            });
          } else {
            return [
              ...prevChat,
              {
                input: prompt,
                output: chunkValue,
              },
            ];
          }
        });
      }
    } catch (error: any) {
      console.log("error", error);
      if (!!error.response) {
        setChatData((prevValue) => {
          return prevValue.filter((_, i) => i !== prevValue.length - 1);
        });
        setError(error.response.data.message ?? error.response.data);

        setTimeout(() => {
          setError(undefined);
        }, 4000);
      }

      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (!currentChat.isFetching && currentChat.input && currentChat.output) {
  //     setChatData((prevChat) => {
  //       return [
  //         ...prevChat,
  //         {
  //           input: currentChat.input,
  //           output: currentChat.output,
  //         },
  //       ];
  //     });
  //     setCurrentChat((currChat) => {
  //       return {
  //         ...currChat,
  //         input: "",
  //         output: "",
  //       };
  //     });
  //   }

  //   return () => {
  //     abortController.abort();
  //   };
  // }, [currentChat.isFetching, currentChat.input, currentChat.output]);

  return { generateChatResponse, chatData, loading, error, setError, currentChat };
};
