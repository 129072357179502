import pepe from "../../assets/images/pepe.png";
import bnb from "../../assets/images/icons/bnb.png";
import btc from "../../assets/images/icons/bitcoin.png";
import busd from "../../assets/images/icons/busd.png";
import usdt from "../../assets/images/icons/usdt.png";
import eth from "../../assets/images/icons/eth.png";
import atom from "../../assets/images/icons/atom.png";
import link from "../../assets/images/icons/link.png";

const farmingData = {
  data: [
    {
      projectTitle: "Active",
      projects: [
        {
          icon: pepe,
          pool: "",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "0 ",
          earned: "$0",
        },
        {
          icon: bnb,
          pool: "WBNB",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "0 ",
          earned: "$0",
        },
        {
          icon: btc,
          pool: "WBTC",
          apy: "0 %",
          tvl: "0 AVAX",
          ASKRequired: "750 ",
          earned: "$0",
        },
        {
          icon: busd,
          pool: "BUSD",
          apy: "0 %",
          tvl: "0 DOT",
          ASKRequired: "500 ",
          earned: "$0",
        },
        {
          icon: usdt,
          pool: "USDT",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "750 ",
          earned: "$0",
        },
        {
          icon: eth,
          pool: "ETH",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "1000 ",
          earned: "$0",
        },
        {
          icon: atom,
          pool: "ATOM",
          apy: "0 %",
          tvl: "0 TT",
          ASKRequired: "1500 ",
          earned: "$0",
        },
        {
          icon: link,
          pool: "LINK",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "2000 ",
          earned: "$0",
        },
      ],
    },
    {
      projectTitle: "Inactive",
      projects: [
        // {
        //   icon: farmIcon4,
        //   pool: "BEST-DOT / BEST",
        //   apy: "39.08 %",
        //   tvl: "356.50 DOT",
        //   ASKRequired: "9.50 DOT",
        //   earned: "$889,155.00",
        // },
        // {
        //   icon: farmIcon5,
        //   pool: "AAVE-BNB / AAVE",
        //   apy: "116.00 %",
        //   tvl: "586.50 BNB",
        //   ASKRequired: "5.50 BNB",
        //   earned: "$905,626.05",
        // },
        // {
        //   icon: farmIcon6,
        //   pool: "SHIB-BNB / SHIB",
        //   apy: "0 %",
        //   tvl: "2586.50 BNB",
        //   ASKRequired: "899.50 BNB",
        //   earned: "$305,626.99",
        // },
        // {
        //   icon: farmIcon7,
        //   pool: "TRX-TT / TRX",
        //   apy: "20.00 %",
        //   tvl: "2586.50 TT",
        //   ASKRequired: "483.50 TT",
        //   earned: "$299,226.03",
        // },
        // {
        //   icon: farmIcon8,
        //   pool: "MFT-BNB / MFT",
        //   apy: "0 %",
        //   tvl: "2586.50 BNB",
        //   ASKRequired: "899.50 BNB",
        //   earned: "$305,626.99",
        // },
      ],
    },
  ],
};

export default farmingData;
