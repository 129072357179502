import { makeStyles } from "../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    // width: "100vw",
    // marginTop: theme.spacing(5),
    // height: "100vh",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",

    },
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // alignItems: "space-aro"
    flexDirection: "row",
    // maxWidth: "1400px",
    height: "100%",
  },
  tabs: {
    // marginTop: "100px",
    width: "100%",
    // padding: "20px",

    display: "flex",
    // height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    gap: "20px",
    [theme.breakpoints.down("md")]: {},
  },
  wrapper: {
    width: "100%",
    position: "relative",

  },
  walletBtn: {
    position: "absolute",
    top: "3%",
    right: "3%",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    }
  },
  headerContent: {
    display: "flex",
    padding: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    width: "100%",
    "& span": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.7rem !importanT"
      },
    },
    "& img": {
      width: "60px",

    },
  },
  logo: {
    display: "flex",

    justifyContent: "center",
    alignItems: "flex-end",
    gap: "3px",
    color: "#ffffff"

  },
  paperProps: {
    width: "32%",
    [theme.breakpoints.down("sm")]: {

      width: "60%",
    }
  }
  ,

  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: "100%",
    margin: "8% auto !important",
    minHeight: "372px",
    padding: "0px 5%",
    gap: "44px",
    // maxWidth: "1200px",
    "& h3": {
      textAlign: "start",
      color: theme.palette.primary.main,
      width: "100%",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      }
    },
    [theme.breakpoints.down(1450)]: {
      // padding: "0px 5%",
    },
    [theme.breakpoints.down("md")]: {
      gap: "44px",
    }
  }

}));
