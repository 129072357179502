import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { SetStateAction } from "react";
import FarmCard from "./FarmCard/FarmCard";
import FarmingStyleWrapper from "./Farming.style";
import farmingData from "./farmingData";
import doge from "../../assets/images/icons/doge.png";
import cake from "../../assets/images/icons/cake.png";
import uni from "../../assets/images/icons/uni.png";
import shiba from "../../assets/images/icons/shiba.png";
import floki from "../../assets/images/icons/floki.png";
import sushi from "../../assets/images/icons/sushi.png";
import { useCleanBgContext } from "../../context/CleanBgContext";
import { theme } from "../../utils/theme";
// import nextArrowIcon from "../../assets/images/icons/next-arrow.png";

const Farm = ({ isCore, setIsCore }: { isCore: boolean; setIsCore: React.Dispatch<SetStateAction<boolean>> }) => {
  const { data } = farmingData;
  const { cleanBg } = useCleanBgContext();
  const altData = [
    {
      projectTitle: "Active",
      projects: [
        {
          icon: doge,
          pool: "DOGE",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "1000 ",
          earned: "$0",
        },
        {
          icon: cake,
          pool: "CAKE",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "1000 ",
          earned: "$0",
          name: "CAKE",
        },
        {
          icon: uni,
          pool: "UNISWAP",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "2000 ",
          earned: "$0",
        },
        {
          icon: shiba,
          pool: "SHIBA",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "1000 ",
          earned: "$0",
        },
        {
          icon: sushi,
          pool: "SUSHI",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "2000 ",
          earned: "$0",
        },
        {
          icon: floki,
          pool: "FLOKI",
          apy: "0 %",
          tvl: "0 BNB",
          ASKRequired: "2000 ",
          earned: "$0",
        },
      ],
    },
    {
      projectTitle: "Inactive",
      projects: [],
    },
  ];

  return (
    <FarmingStyleWrapper cleanBg={cleanBg ? "#d3d2d2" : theme.palette.background.default}>
      <div style={{ width: "100%" }} className="container">
        <Tabs>
          <TabList>
            <div style={{ listStyle: "none" }} className="tab_btn_wrapper">
              {data?.map((child, i) => (
                <Tab key={i}>
                  <button style={cleanBg ? { color: "black" } : {}}>{child.projectTitle}</button>
                </Tab>
              ))}
              <div className="farm_sorting_switch">
                <label className="switch">
                  <input onChange={() => setIsCore(() => !isCore)} type="checkbox" />
                  <span className="slider round"></span>
                </label>
                <span style={cleanBg ? { color: "black" } : {}} className="title">
                  Alts
                </span>
              </div>
            </div>

            <div className="item_sorting_list">
              {/* <button>
                                All Access
                                <img src={nextArrowIcon} alt="icon" />
                                <ul className="sub-menu">
                                    <li>All Pools</li>
                                    <li>Public</li>
                                    <li>Private</li>
                                    <li>Community</li>
                                </ul>
                            </button> */}
            </div>
          </TabList>

          <>
            {isCore === true ? (
              <>
                {data?.map((items, i) => (
                  <TabPanel key={i} className="tabs-row">
                    {items.projects?.map((project, i) => {
                      return <FarmCard key={i} {...project} />;
                    })}
                  </TabPanel>
                ))}
              </>
            ) : (
              <>
                {altData?.map((items, i) => (
                  <TabPanel key={i} className="tabs-row">
                    {items.projects?.map((project, i) => {
                      return <FarmCard key={i} {...project} />;
                    })}
                  </TabPanel>
                ))}
              </>
            )}
          </>
        </Tabs>
      </div>
    </FarmingStyleWrapper>
  );
};

export default Farm;
