import { Button, TextField, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useEffect, useRef, useState } from "react";
import { useChat } from "../../hooks/useChat";
import pepe from "../../assets/images/pepe.png";
import inputUser from "../../assets/icons/inputUser.png";
import microphone from "../../assets/icons/microphone.png";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import CountdownTimer from "../Countdown/Countdown";
import { TIME_TO_PRESALE } from "../../utils";
import Markdown from "markdown-to-jsx";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
const Chat = () => {
  const { classes } = useStyles();
  const [prompt, setPrompt] = useState<string>("");
  const [isListening, setIsListening] = useState(false);
  const { generateChatResponse, loading, chatData, error, currentChat } = useChat();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const outputContainerRef = useRef<HTMLDivElement>(null);

  const { transcript } = useSpeechRecognition();

  async function handleGenerateChatRes(prompt: string) {
    if (!prompt || prompt === "") {
      setErrorMessage("Prompt is required");
      return;
    }
    setErrorMessage(undefined);

    await generateChatResponse(prompt);

    setPrompt("");
  }

  useEffect(() => {
    (outputContainerRef.current as HTMLDivElement).scrollTo(
      0,
      (outputContainerRef.current as HTMLDivElement).scrollHeight
    );
  }, [chatData]);

  const handleSpeech = () => {
    if (!isListening) {
      SpeechRecognition.startListening({
        continuous: true,
      });
      setIsListening(true);
    } else {
      SpeechRecognition.stopListening();
      setIsListening(false);
      setPrompt(transcript);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        {error && (
          <Typography variant="body1" color="text.primary" textAlign={"center"}>
            {typeof error === "string" ? error : "Something went wrong. Please try again later.."}
          </Typography>
        )}
        <div className={classes.outputContainer} ref={outputContainerRef}>
          {/* {chatData.length === 0 && (
        <Typography
          variant="h5"
          sx={{ color: "rgba(255,255,255, 0.3)" }}
          textAlign={"center"}
        >
          Let's start chating
        </Typography>
      )} */}
          <div className={classes.outputWrapper}>
            {chatData.map((data) => (
              <div className={classes.output} key={crypto.randomUUID()}>
                <div className={classes.inputDiv}>
                  {/* <img src={inputUser} width="50px" alt="user" /> */}
                  <div
                    style={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      backgroundColor: "gray",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PersonOutlineIcon sx={{ color: "#ffffff" }} />
                  </div>
                  <Typography
                    sx={{
                      whiteSpace: "pre-wrap",
                    }}
                    color="text.primary"
                    variant="body2"
                    component="p"
                  >
                    {data.input}
                  </Typography>
                </div>
                <div className={classes.outputDiv}>
                  {"\n\n"}
                  <img src={pepe} width="50px" className={classes.outputImage} />
                  <Typography
                    sx={{
                      marginTop: "40px",
                      whiteSpace: "pre-wrap",
                    }}
                    color="text.primary"
                    variant="body2"
                    component="p"
                  >
                    <Markdown>{data.output as string}</Markdown>
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        {errorMessage && (
          <Typography color="text.primary" textAlign={"center"}>
            {errorMessage}
          </Typography>
        )}
        <div className={classes.inputWrapper}>
          <TextField
            name="prompt"
            placeholder="What are you looking for?"
            variant="standard"
            value={prompt}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleGenerateChatRes(prompt);
              }
            }}
            onChange={(e) => setPrompt(e.target.value)}
            className={classes.inputFieldContainer}
            InputProps={{
              endAdornment: (
                <>
                  <img
                    src={microphone}
                    onClick={handleSpeech}
                    style={isListening ? { opacity: "0.2", cursor: "pointer" } : { cursor: "pointer" }}
                    alt="mic"
                    width="30px"
                  />
                  <Button className={classes.btn} disabled={loading} onClick={() => handleGenerateChatRes(prompt)}>
                    {loading ? "..." : "Send"}
                  </Button>
                </>
              ),
              // disableUnderline: true,
            }}
            inputProps={{
              className: classes.input,
            }}
          />
        </div>
        <CountdownTimer date={TIME_TO_PRESALE} />
      </div>
    </div>
    // <div className={classes.root}>
    //   <form onSubmit={() => handleGenerateChatRes}>
    //     <div className={classes.inputWrapper}>
    //       <TextField
    //         name="prompt"
    //         placeholder="Ask me anything..."
    //         variant="standard"
    //         value={prompt}
    //         onChange={(e) => setPrompt(e.target.value)}
    //         className={classes.inputFieldContainer}
    //         InputProps={{
    //           disableUnderline: true,
    //         }}
    //         inputProps={{
    //           className: classes.input,
    //         }}
    //       />
    //       <Button
    //         variant="contained-custom"
    //         className={classes.btn}
    //         disabled={loading}
    //         onClick={() => handleGenerateChatRes(prompt)}
    //         type="submit"
    //       >
    //         Send
    //       </Button>
    //     </div>
    //   </form>
    //   {error && (
    //     <Typography variant="body1" color="text.primary" textAlign={"center"}>
    //       {typeof error === "string" ? error : "Something went wrong. Please try again later.."}
    //     </Typography>
    //   )}
    //   <div className={classes.outputContainer}>
    //     {isLoading && (
    //       <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
    //         <img alt="pepe" src={pepe} width="50px" className={classes.outputImage} />

    //         <Typography
    //           sx={{
    //             whiteSpace: "pre-wrap",
    //           }}
    //           color="text.primary"
    //           variant="body2"
    //           component="p"
    //         >
    //           Typing...
    //         </Typography>
    //       </div>
    //     )}
    //     {chatData.length === 0 && (
    //       <Typography variant="h5" sx={{ color: "rgba(255,255,255, 0.3)" }} textAlign={"center"}>
    //         Let's start chating
    //       </Typography>
    //     )}
    //     {chatData.map((data) => (
    //       <div key={crypto.randomUUID()} className={classes.output}>
    //         <div style={{ display: "flex", gap: "10px" }}>
    //           <img alt="pepe" src={pepe} width="50px" className={classes.outputImage} />
    //           <div
    //             className={classes.outputDiv}
    //             style={{
    //               padding: "15px",
    //               display: "flex",
    //               alignItems: "center",
    //               border: "1px solid #FCCA0330",
    //               backgroundColor: "#050505",
    //               borderRadius: "15px",
    //               maxWidth: "80%",
    //             }}
    //           >
    //             <Typography color="text.primary" variant="body2" component="p">
    //               {data.output}
    //             </Typography>
    //           </div>
    //         </div>
    //         <div
    //           style={{
    //             display: "flex",
    //             gap: "10px",
    //             justifyContent: "flex-end",
    //             marginTop: "1rem",
    //           }}
    //         >
    //           <div
    //             className={classes.inputDiv}
    //             style={{
    //               flex: 1,
    //               border: "1px solid #FCCA0350",
    //               padding: "15px",
    //               backgroundColor: "#151313",
    //               borderRadius: "15px",
    //               maxWidth: "80%",
    //             }}
    //           >
    //             <Typography color="text.primary" variant="body2" component="p">
    //               {data.input}
    //             </Typography>
    //           </div>
    //           <img alt="userinput" src={inputUser} width="50px" className={classes.outputImage} />
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
};

export default Chat;
