import { makeStyles } from "../../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    // display: "flex",
    // flexDirection: "column",

    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
    gridGap: "30px",
    grid: "30px",
    width: "100%",

  },
  showStyle: {
    cursor: "pointer",
    width: "100%",
    maxWidth: "335.51px",
    // display: "fixed",
    display: "flex",
    justifyContent: "center",
    // margin: "20px",

  },
  btn: {
    maxWidth: "335.51px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "& svg": {
      transition: "all 0.3s ease-in-out",
    },
    width: '100%',
    [theme.breakpoints.down("lg")]: {
      width: "235.51px !important",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "174.51px",
      width: "100% !important",
    },
  },
  modelContainer: {
    // minWidth: "150px",
    // minHeight: "150px",

    userSelect: "none",
    position: "relative",
    padding: "3px",
    // border: `2px solid ${theme.palette.secondary.main}`,
    margin: 0,
    // boxShadow: `5px 5px 10px ${theme.palette.secondary.main}`,
    cursor: "pointer",
    // borderRadius: "10px",
    "&:hover": {
      transform: "scale(1.1)",
    },

    // [theme.breakpoints.down("sm")]: {
    // alignSelf: "center",
    // marginLeft: "20px",
    // width: "85%",
    // },
  },
  checkmark: {
    position: 'absolute',
    top: '-31px',
    left: '-15px',
    zIndex: '1000',
    width: '26px',

    [theme.breakpoints.down("sm")]: {
      width: "30px",
      height: "30px",
      top: "-10px",
      left: "-10px",
    },
  },
  image: {
    // backgroundBlendMode: "",
    // mixBlendMode: "",
    objectFit: "contain",
    height: "100%",
    width: "100%",
  },
  name: {
    width: "100%",
    color: theme.palette.text.primary,
    position: "absolute",
    textAlign: "center",
    bottom: 10,
  },
  wrapper: {
    position: "relative",
    width: '100%',
    zIndex: 999
  },
  content: {
    display: 'flex',
    minHeight: '248px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    height: "100%",
    position: "absolute",
    top: "5%",
  },


  menuContainer: {
    ".MuiPaper-root": {
      width: "100%",
      maxWidth: "337px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "auto",

      }
    },
  }
}));
