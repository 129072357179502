import { useStyles } from "./styles";
import { Typography } from "@mui/material";
import openai from "../../assets/icons/openai.png";
import openaiBlack from "../../assets/icons/OpenAI_black.png";
import BackgroundCanvas from "../../components/BackgroundCanvas/BackgroundCanvas";
import Header from "../../components/Header/Header";
import { useCleanBgContext } from "../../context/CleanBgContext";

const MainContainer = ({ children, mainContainer }: { children: JSX.Element; mainContainer?: boolean }) => {
  const { classes } = useStyles();
  const { cleanBg } = useCleanBgContext();

  return (
    <div style={mainContainer !== true ? (cleanBg ? {} : { backgroundColor: "#141414" }) : {}} className={classes.root}>
      <BackgroundCanvas cleanBg={cleanBg} />
      {/* <div className={classes.iconsContainer}> */}
      {/* <Header /> */}
      {/* <IconsContainer /> */}
      {/* </div> */}
      {mainContainer ? (
        <div className={classes.mainContainer}>
          {/* <div className={classes.imgContainer}>
          <img src={logo} width="180" />
        </div> */}

          {children}
        </div>
      ) : (
        <div
          style={
            cleanBg
              ? {
                  display: "flex",
                  flexDirection: "column",
                  // backgroundColor: "#141414",
                  paddingLeft: "20px",
                  paddingRight: "30px",
                }
              : {
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#141414",
                  paddingLeft: "20px",
                  paddingRight: "30px",
                }
          }
        >
          {children}
        </div>
      )}
      <div className={classes.poweredBy}>
        {cleanBg ? (
          <>
            <Typography style={{ color: "black" }}>Powered By</Typography>
            <img alt="powered by OpenAI" className={classes.poweredByImage} src={openaiBlack} />
          </>
        ) : (
          <>
            <Typography style={{ color: "white" }}>Powered By</Typography>
            <img alt="powered by OpenAI" className={classes.poweredByImage} src={openai} />
          </>
        )}
      </div>
    </div>
  );
};

export default MainContainer;
