import { Provider } from "@wagmi/core";
import { ethers } from "ethers";
import { useCallback, useState } from "react";
import { useProvider } from "wagmi";
import { apiInstance } from "../config/apiConfig";
import { contractConfigs } from "../contracts";
import { useConnectWallet } from "./useConnectWallet";
import { useAskAnyNFTContract } from "./useContracts";

async function parseEventLogs(hash: string, provider: Provider, abi: any[]) {
  const reciept = await provider.getTransactionReceipt(hash);

  const iEvents = new ethers.utils.Interface(abi);

  let eventInfo = [];

  for (let i = 0; i < reciept.logs.length; i++) {
    const log = reciept.logs[i];
    try {
      const eventData = iEvents.parseLog({ data: log.data, topics: log.topics });
      eventInfo.push(eventData);
    } catch (error) {
      continue;
    }
  }

  return eventInfo;
}

export const useMint = () => {
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const { address, isConnected, chainSupported, connectWallet, networkSwitch } = useConnectWallet();
  const { askanyNftContract } = useAskAnyNFTContract();
  const [showToast, setShowToast] = useState<{
    status: boolean;
    url: string | null;
  }>({
    status: false,
    url: null,
  });
  const provider = useProvider();
  var abi = [
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "from", type: "address" },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        { indexed: true, internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "Transfer",
      type: "event",
    },
  ];

  const mint = useCallback(
    async ({
      prompt,
      modelId,
      imageUrl,
      index,
    }: {
      prompt: string;
      modelId: string;
      imageUrl: string;
      index: number;
    }) => {
      try {
        setLoadingIndex(index);
        const res = await apiInstance.post("/store-metadata", {
          imageUrl,
          prompt,
          modelId,
          userAddress: address,
        });

        if (res.data.metadataUrl) {
          const txn = await askanyNftContract?.mint(address, res.data.metadataUrl);
          await txn.wait();

          if (txn.hash) {
            const eventsLogs = await parseEventLogs(txn.hash, provider, abi);

            const log = eventsLogs[0];

            const tokenId = ethers.BigNumber.from(log.args.tokenId).toString();
            const openseaUrl = `https://testnets.opensea.io/assets/goerli/${contractConfigs.AskAnyNFT?.address}/${tokenId}`;

            setShowToast({
              status: true,
              url: openseaUrl,
            });
          }
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        setLoadingIndex(null);
      }
    },
    [address, askanyNftContract]
  );

  return { mint, connectWallet, networkSwitch, chainSupported, isConnected, loadingIndex, showToast, setShowToast };
};
