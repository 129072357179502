import { configureChains, createClient, goerli } from "wagmi";
import { arbitrum, bsc } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { getDefaultWallets } from "@rainbow-me/rainbowkit";

// import { bscTestnet } from "wagmi/chains";
// const bscTestnet: Chain = {
//   id: 97,
//   network: "BSC",
//   name: "Binance Smart Chain test network",
//   rpcUrls: {
//     default: {
//       http: ["https://data-seed-prebsc-1-s3.binance.org:8545"],
//     },

//     public: {
//       http: ["https://data-seed-prebsc-1-s3.binance.org:8545"],
//     },
//   },
//   nativeCurrency: {
//     name: "Binance Chain Native Token",
//     decimals: 18,
//     symbol: "TBNB",
//   },
//   blockExplorers: {
//     default: {
//       name: "Smart Chain - Testnet",
//       url: "https://testnet.bscscan.com",
//     },
//   },
//   testnet: true,
// };

const { chains, provider } = configureChains([arbitrum, bsc], [publicProvider()]);

const { connectors } = getDefaultWallets({
  appName: "PepePunks",
  projectId: "PepePunks",
  chains,
});

export const networkChains = chains;

export const client = createClient({
  autoConnect: true,
  connectors,
  provider,
});
