import { Button, Typography, Box } from "@mui/material";
import { useStyles } from "./styles";
import checkmark from "../../../assets/icons/checkmark.svg";
import { useEffect, useState } from "react";
import { TextToImageInputTypes } from "../../../utils/types";
import { apiInstance, BASE_DOMAIN } from "../../../config/apiConfig";
import placeholderImage from "../../../assets/images/placeholderimage.jpg";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { theme } from "../../../utils/theme";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const DiffusionModelSelect = ({
  setInputData,
  children,
}: {
  setInputData: React.Dispatch<React.SetStateAction<TextToImageInputTypes>>;
  children: React.ReactNode;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { classes } = useStyles();
  const [selected, setSelected] = useState<string>("");
  const [showStyles, setShowStyles] = useState<{ modal: string; isOpen: boolean; event: null | HTMLElement }>({
    modal: "Show Styles",
    isOpen: false,
    event: null,
  });
  const [models, setModels] = useState<
    {
      model_name: string;
      model_id: string;
      url: string;
      image_url: string;
    }[]
  >([]);

  const handleSelected = (name: string) => {
    setSelected(name);
    setInputData((prevValue) => ({
      ...prevValue,
      modelId: name,
    }));
  };

  useEffect(() => {
    const fetchModelsList = async () => {
      const { data } = await apiInstance.get("/models-list");
      console.log("data: ", data);

      setModels(data.data);
      setSelected(data.data[0].model_id);
    };

    fetchModelsList();
  }, []);

  return (
    <>
      <Box className={classes.wrapper}>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined-custom"
            size="large"
            className={classes.btn}
            color="secondary"
            onClick={(e) => {
              setShowStyles((prev) => {
                return { ...prev, isOpen: !prev.isOpen, event: e?.currentTarget };
              });
            }}
          >
            <span>{showStyles.modal}</span>
            <ExpandLessIcon sx={{ transform: `rotate(${showStyles.isOpen ? 180 : 0}deg)` }} />
          </Button>
          {children}
          <Menu
            className={classes.menuContainer}
            id="basic-menu"
            anchorEl={showStyles.event}
            open={showStyles.isOpen}
            onClose={() =>
              setShowStyles((prev) => {
                return { ...prev, isOpen: false };
              })
            }
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {models.map((x) => (
              <MenuItem
                onClick={(e) => {
                  handleSelected(x.model_id);
                  setShowStyles({ isOpen: false, modal: x.model_name, event: e.currentTarget });
                }}
                sx={{
                  borderBottom: `1px solid ${selected === x.model_id ? theme.palette.secondary.main : "transparent"}`,
                }}
              >
                {x.model_name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Box>
    </>
  );
};

export default DiffusionModelSelect;
