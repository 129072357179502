import { Button, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import pepe from "../../assets/images/pepe.png";
import { useStyles } from "./styles";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { theme } from "../../utils/theme";

const CustomTabs = ({
  tabs,
  value,
  onTabChange,
  className,
}: {
  tabs: string[];
  value: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  className?: string;
}) => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box className={classes.root}>
      <Box className={classes.mainContainer}>
        <Typography variant="h4">Pepe Punks</Typography>
        <img src={pepe} alt="Pepe" />
        <Box className={className}>
          {/* <StyledTabs value={value} onChange={onTabChange}> */}
          {tabs.map((tab, i) => (
            <Button
              onClick={(e) => onTabChange(e, i)}
              key={tab}
              variant={i === value ? "contained-custom" : "text"}
              sx={{ minWidth: "100%", textAlign: "left" }}
            >
              {tab}
              {/* {tab === "Video" && (
            <div style={{ fontSize: "10px" }}>
              <br />
              (Soon)
            </div>
          )} */}
            </Button>
          ))}
          {/* <span style={{ display: isMobile ? "flex" : "none", justifyContent: "center", alignItems: "center" }}>
            <ConnectButton />
          </span> */}
          {/* </StyledTabs> */}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomTabs;
