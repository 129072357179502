import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSigner } from "wagmi";
import arrowBack from "../../assets/icons/arrowleft.png";
import { useWalletModalContext } from "../../context/WalletModalContext";
import { useMint } from "../../hooks/useMint";
import InputForm from "./InputForm/InputForm";
import Timer from "./Timer/Timer";
import { toast } from "react-toastify";

const ImageModal = ({
  isOpen,
  setIsOpen,
  classes,
  imagesData,
  // setShowDownloadBtnFor,
  // showDownloadBtnFor,
  setError,
  eta,
  processing,
  cannotFetch,
  error,
  downloadImage,
  loading,
  handleImageGenerate,
  apiType,
}: any) => {
  const [openPrompt, setOpenPrompt] = useState<{
    status: boolean;
    imageUrl: string | null;
  }>({ status: false, imageUrl: null });

  const { handleOpenConnectionModal } = useWalletModalContext();
  const { data: signer } = useSigner();
  const {
    mint,
    isConnected,
    networkSwitch,
    chainSupported,
    loadingIndex: mintLoadingIndex,
    showToast,
    setShowToast,
  } = useMint();
  const [mintData, setMintData] = useState<{
    imageUrl: string;
    prompt: string;
    modelId: string;
    imgIndex: number | null;
  } | null>(null);

  const handleMintButton = ({
    imageUrl,
    prompt,
    modelId,
    imgIndex,
  }: {
    imageUrl: string;
    prompt: string;
    modelId: string;
    imgIndex: number;
  }) => {
    if (!isConnected) {
      handleOpenConnectionModal(true);
      setMintData({
        imageUrl,
        prompt,
        modelId,
        imgIndex,
      });

      return;
    }

    if (!chainSupported) {
      networkSwitch();
      setMintData({
        imageUrl,
        prompt,
        modelId,
        imgIndex,
      });

      return;
    }

    mint({
      imageUrl,
      prompt,
      modelId,
      index: imgIndex,
    });
  };

  useEffect(() => {
    if (isConnected && chainSupported && mintData && signer) {
      mint({
        prompt: mintData.prompt,
        imageUrl: mintData.imageUrl,
        index: mintData.imgIndex as number,
        modelId: mintData.modelId,
      });
      setMintData(null);
    }
  }, [mintData, mint, isConnected, chainSupported, signer]);

  useEffect(() => {
    if (showToast.status) {
      toast.success(
        <div style={{ zIndex: 10000 }}>
          Minted Successfully! <br /> View on:{" "}
          <a href={showToast.url as string} target="_blank">
            OpenSea
          </a>
        </div>,
        {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        }
      );

      setShowToast({
        status: false,
        url: null,
      });
    }
  }, [showToast.status, showToast.url]);

  return (
    <Dialog
      open={isOpen}
      // onClose={() => setIsOpen(false)}
      maxWidth="xl"
      className={classes.dialog}
      PaperProps={{
        style: { backgroundColor: "transparent", boxShadow: "none" },
      }}
    >
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        <img src={arrowBack} alt="back-arrow" onClick={() => setIsOpen(false)} className={classes.backButton} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {imagesData?.images?.map((image: any, i: number) => (
          <div
            key={i}
            style={loading ? { filter: "blur(4px)", pointerEvents: "none" } : {}}
            className={classes.outputImageContainer}
            // onMouseOver={() => setShowDownloadBtnFor(i)}
            // onMouseLeave={() => setShowDownloadBtnFor(null)}
          >
            <img src={image} className={classes.outputImage} alt="generated" />

            {/* {showDownloadBtnFor === i && ( */}
            <div className={classes.btnsContainer}>
              <Button
                disabled={mintLoadingIndex === i}
                // disabled={!isConnected}
                variant="outlined-custom"
                className={classes.actionBtn}
                onClick={() =>
                  handleMintButton({
                    imageUrl: image,
                    prompt: imagesData.meta.prompt,
                    modelId: imagesData.meta.model_id,
                    imgIndex: i,
                  })
                }
              >
                Mint
              </Button>
              <Button
                variant="outlined-custom"
                className={classes.actionBtn}
                onClick={() => downloadImage(image, `${imagesData.meta.prompt}-${i + 1}.png`)}
              >
                Download
              </Button>
              <Button
                className={classes.actionBtn}
                variant="outlined-custom"
                // className={classes.variationBtn}
                onClick={() =>
                  handleImageGenerate(
                    {
                      prompt: imagesData.meta.prompt,
                      modelId: imagesData.meta.model_id,
                      negativePrompt: imagesData.meta.negative_prompt,
                      initialImageUrl: image,
                    },
                    apiType.IMAGE2IMAGE.endpoint
                  )
                }
              >
                Variation
              </Button>
              <Button
                className={classes.actionBtn}
                variant="outlined-custom"
                // className={classes.variationBtn}
                onClick={() =>
                  setOpenPrompt((prev) => ({
                    status: !prev.status,
                    imageUrl: !prev.status ? image : null,
                  }))
                }
              >
                Variation + prompt
              </Button>
            </div>
            {/* )} */}
          </div>
        ))}
      </DialogContent>
      {openPrompt.status && (
        <div style={{ maxHeight: "400px", zIndex: 2, width: "98%" }}>
          <InputForm
            handleImageGenerate={handleImageGenerate}
            loading={loading}
            setError={setError}
            time={eta}
            isModal={true}
            openPrompt={openPrompt}
            setOpenPrompt={setOpenPrompt}
          >
            {!!error && (
              <Typography color="text.primary" textAlign="center">
                {error}
              </Typography>
            )}
            {processing && <Timer time={eta} textType="Images" />}
            {cannotFetch && (
              <Typography color="text.primary" textAlign="center">
                Cannot generate images for the time being... Please try again later..
              </Typography>
            )}
          </InputForm>
        </div>
      )}
    </Dialog>
  );
};

export default ImageModal;
