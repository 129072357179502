import React, { createContext, useState, useContext } from "react";

const initialState = {
  cleanBg: (JSON.parse(localStorage.getItem("cleanBg") as string) as boolean) ?? false,
  handleSetCleanBg: () => {},
};

const CleanBgContext = createContext(initialState);

export const useCleanBgContext = () => useContext(CleanBgContext);

const CleanBgContextProvider = ({ children }: { children: JSX.Element }) => {
  const [cleanBg, setCleanBg] = useState(initialState.cleanBg);

  const handleSetCleanBg = () => {
    setCleanBg((prev) => !prev);
  };

  React.useEffect(() => {
    localStorage.setItem("cleanBg", String(cleanBg));
  }, [cleanBg]);

  return (
    <CleanBgContext.Provider
      value={{
        cleanBg,
        handleSetCleanBg,
      }}
    >
      {children}
    </CleanBgContext.Provider>
  );
};

export default CleanBgContextProvider;
