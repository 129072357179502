import { useContract, useProvider, useSigner } from "wagmi";
import { contractConfigs } from "../contracts";

export const useMockLpTokenContract = () => {
  const { data: signer } = useSigner();
  const provider = useProvider();

  const mockLpTokenContract = useContract({
    abi: contractConfigs.MockLPToken.abi,
    address: contractConfigs.MockLPToken.address as `0x${string}`,
    signerOrProvider: signer ?? provider,
  });

  return {
    mockLpTokenContract,
  };
};

export const useStakingContract = () => {
  const { data: signer } = useSigner();
  const provider = useProvider();

  const stakingContract = useContract({
    abi: contractConfigs.Staking.abi,
    address: contractConfigs.Staking.address as `0x${string}`,
    signerOrProvider: signer ?? provider,
  });

  return {
    stakingContract,
  };
};

export const useAskAnyAIContract = () => {
  const { data: signer } = useSigner();
  const provider = useProvider();

  const askanyContract = useContract({
    abi: contractConfigs.AskAnyAi.abi,
    address: contractConfigs.AskAnyAi.address as `0x${string}`,
    signerOrProvider: signer ?? provider,
  });

  return {
    askanyContract,
  };
};

export const useAskAnyNFTContract = () => {
  const { data: signer } = useSigner();
  const provider = useProvider();

  const askanyNftContract = useContract({
    abi: contractConfigs.AskAnyNFT?.abi,
    address: contractConfigs.AskAnyNFT?.address as `0x${string}`,
    signerOrProvider: signer ?? provider,
  });

  return { askanyNftContract };
};
