import anythingv3 from "../assets/diffusionmodels/anythingv3.png";
import arcanediffusion from "../assets/diffusionmodels/arcanediffusion.png";
import deliberate from "../assets/diffusionmodels/deliberate.png";
import doubleexposure from "../assets/diffusionmodels/doubleexposure.png";
import firewatchdiffusion from "../assets/diffusionmodels/firewatchdiffusion.png";
import jakscreepycritterpack from "../assets/diffusionmodels/jakscreepycritterpack.png";
import linkedinps from "../assets/diffusionmodels/linkedinps.png";
import midjourney from "../assets/diffusionmodels/midjourney.png";
import midjourneypapercut from "../assets/diffusionmodels/midjourneypapercut.png";
import porttraitplus from "../assets/diffusionmodels/porttraitplus.png";
import realisticvision from "../assets/diffusionmodels/realisticvision.png";
import robodiffusion from "../assets/diffusionmodels/robodiffusion.png";
import synthwavepunk from "../assets/diffusionmodels/synthwavepunk.png";
import wifudiffusion from "../assets/diffusionmodels/wifudiffusion.png";

export const models = [
  {
    model_name: "Realisct",
    model_id: "realistic-vision-v13",
    url: "stablediffusionapi/realistic-vision-v13",
    image_url: realisticvision,
  },
  {
    model_name: "Dreamweaver",
    model_id: "midjourney",
    url: "prompthero/openjourney",
    image_url: midjourney,
  },
  {
    model_name: "Profile",
    model_id: "linkedin-diffusion",
    url: "prompthero/linkedin-diffusion",
    image_url: linkedinps,
  },
  {
    model_name: "Dream",
    model_id: "deliberate",
    url: "peterwilli/deliberate",
    image_url: deliberate,
  },
  {
    model_name: "Plus",
    model_id: "portraitplus-diffusion",
    url: "wavymulder/portraitplus",
    image_url: porttraitplus,
  },
  {
    model_name: "Double",
    model_id: "exposure-diffusion",
    url: "joachimsallstrom/Double-Exposure-Diffusion",
    image_url: doubleexposure,
  },
  {
    model_name: "Anything",
    model_id: "anything-v3",
    url: "Linaqruf/anything-v3.0",
    image_url: anythingv3,
  },
  {
    model_name: "Wifu",
    model_id: "wifu-diffusion",
    url: "hakurei/waifu-diffusion",
    image_url: wifudiffusion,
  },
  {
    model_name: "Robot",
    model_id: "robo-diffusion",
    url: "nousr/robo-diffusion",
    image_url: robodiffusion,
  },
  {
    model_name: "Synth",
    model_id: "synthwave-diffusion",
    url: "ItsJayQz/SynthwavePunk-v2",
    image_url: synthwavepunk,
  },
  {
    model_name: "Firewatch",
    model_id: "firewatch-diffusion",
    url: "ItsJayQz/Firewatch_Diffusion",
    image_url: firewatchdiffusion,
  },
  {
    model_name: "Paper Trail",
    model_id: "midjourney-papercut",
    url: "ShadoWxShinigamI/MidJourney-PaperCut",
    image_url: midjourneypapercut,
  },
  {
    model_name: "Arcane",
    model_id: "arcane-diffusion",
    url: "nitrosocke/Arcane-Diffusion",
    image_url: arcanediffusion,
  },
  {
    model_name: "Creepy",
    model_id: "food-crit",
    url: "plasmo/food-crit",
    image_url: jakscreepycritterpack,
  },
];
