import { useState } from "react";
import { apiInstance } from "../config/apiConfig";
import { apiType, sleep } from "../utils";
import { TextToImageInputTypes } from "../utils/types";

enum IMAGES_STATUS {
  AVAILABLE = "available",
  CANNOT_FETCH = "cannot fetch",
}

interface InputData {
  prompt: string;
  model_id: string;
  negative_prompt: string;
  [key: string]: string | undefined;
}

let imagesFetchCount = 0;

async function checkIfImagesAvailable(
  imagesLinks: string[],
  checkAfterSeconds: number,
  fetchNoOfTimes: number = 40 / checkAfterSeconds
): Promise<IMAGES_STATUS> {
  if (imagesFetchCount >= fetchNoOfTimes) {
    return IMAGES_STATUS.CANNOT_FETCH;
  }

  const { data: imageCheckData } = await apiInstance.post("/images-available", {
    images: imagesLinks,
  });

  imagesFetchCount += 1;

  if (!imageCheckData.imagesAvailable) {
    await sleep(checkAfterSeconds);

    return await checkIfImagesAvailable(imagesLinks, checkAfterSeconds);
  }

  return IMAGES_STATUS.AVAILABLE;
}

export const useTextToImageGenerator = () => {
  const [loading, setLoading] = useState(false);
  const [imagesData, setImagesData] = useState<{
    meta: {
      prompt: string;
      negative_prompt: string;
      model_id: string;
    };
    images: string[];
  }>();
  const [eta, setEta] = useState<number | undefined>();
  const [processing, setProcessing] = useState(false);
  const [cannotFetch, setCannotFetch] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generate = async (inputData: TextToImageInputTypes, endpoint: string) => {
    setError(null);

    imagesFetchCount = 0;

    try {
      if (endpoint === null) {
        setError("Please select type first");
        return;
      }

      let reqBody: InputData = {
        prompt: inputData.prompt,
        model_id: inputData.modelId,
        negative_prompt: inputData.negativePrompt,
      };

      if (endpoint === apiType.IMAGE2IMAGE.endpoint || endpoint === apiType.IMAGE_SEGMENTATION.endpoint) {
        reqBody["init_image"] = inputData.initialImageUrl;
      }

      setLoading(true);
      const { data } = await apiInstance.post(endpoint, {
        ...reqBody,
      });

      if (data.status === "processing") {
        setCannotFetch(false);
        setProcessing(true);
        const now = Date.now() / 1000;
        setEta(now + (data.eta as number));

        await sleep(data.eta);
        let status;
        let imageLinks = [];

        if (endpoint === apiType.IMAGE2IMAGE.endpoint) {
          imageLinks = [...data.image_links];
          status = await checkIfImagesAvailable(imageLinks, 4);
        } else if (endpoint === apiType.IMAGE_SEGMENTATION.endpoint) {
          for (let i = 0; i < data.meta.n_samples; i++) {
            imageLinks.push(
              `https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/${data.meta.file_prefix}-${i}.png`
            );
          }

          status = await checkIfImagesAvailable([...imageLinks], 4);
        }

        if (status === IMAGES_STATUS.CANNOT_FETCH) {
          setCannotFetch(true);
          setProcessing(false);

          setTimeout(() => setCannotFetch(false), 4000);
        }

        if (status === IMAGES_STATUS.AVAILABLE) {
          setImagesData({
            meta: {
              prompt: data.meta.prompt,
              model_id: data.meta.model_id,
              negative_prompt: data.meta.negative_prompt,
            },
            images: imageLinks,
          });
          setProcessing(false);
        }
      }

      if (data.status === "success") {
        const status = await checkIfImagesAvailable(data.output, 2);

        if (status === IMAGES_STATUS.AVAILABLE) {
          setImagesData({
            meta: {
              prompt: data.meta.prompt,
              model_id: data.meta.model_id,
              negative_prompt: data.meta.negative_prompt,
            },
            images: data.output,
          });
        }
      }

      if (data.status === "failed") {
        setError(data.message);
      }

      if (data.status === "error") {
        if (typeof data.message === "string") {
          setError(data.message);
        } else if (typeof data.message === "object") {
          const value: string[] = Object.values(data.message);

          setError(value[0]);
        }
      }
    } catch (error: any) {
      if (!!error.response) {
        setError(error.response.data.message ?? error.response.data);
      }

      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    eta,
    error,
    generate,
    setError,
    cannotFetch,
    loading,
    processing,
    imagesData,
  };
};
