import { Typography } from "@mui/material";

import moment from "moment";
import { Area, AreaChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { useCleanBgContext } from "../../context/CleanBgContext";
import { useStyles } from "./styles";

interface CardWithGraphType<T> {
  data: T[];
  xAxisDataKey: string;
  yAxisDataKey: string;
  title: string;
}

function getFormattedPayloadName(name?: NameType) {
  if (name === "liquidity_usd") return "Liquidity USD";
  if (name === "volume24h_usd") return "24 hrs Volume (USD)";
}

const DataFormater = (number: number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ padding: "2px 10px", background: "white" }}>
        <p>
          Date: <b>{formatXAxis(label)}</b>
        </p>
        <p className="label">
          {getFormattedPayloadName(payload[0].name)} : <b>{payload[0].value?.toLocaleString()}</b>
        </p>
      </div>
    );
  }

  return null;
};

function formatXAxis(tickItem: number) {
  return moment(tickItem * 1000).format("L");
}

const fakeData = [
  {
    address: "0x25fd42d82d5c238ee7aa277261aa6ca5bdfe5cd4",
    liquidity: 0,
    liquidity_usd: 0,
    price_eth: 0,
    price_usd: 0,
    timestamp: 1682956800,
    volume24h_eth: 0,
    volume24h_usd: 0,
  },
];

function CardWithGraph<T>({ data, xAxisDataKey, yAxisDataKey, title }: CardWithGraphType<T>) {
  const { classes } = useStyles();
  const { cleanBg } = useCleanBgContext();

  return (
    <div className={classes.root}>
      <Typography
        variant="h4"
        color={cleanBg ? "" : "text.primary"}
        textAlign={"center"}
        sx={{ mb: 2 }}
        style={{ paddingTop: "15px", alignSelf: "center" }}
      >
        {title}
      </Typography>
      <ResponsiveContainer height={180} width={"99%"}>
        <AreaChart data={fakeData}>
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ffdd00" stopOpacity={1} />
              <stop offset="95%" stopColor="#c4bc4c" stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <XAxis
            tick={{ fill: "#ffffff" }}
            dataKey={xAxisDataKey}
            tickFormatter={formatXAxis}
            color={cleanBg ? "#000" : "#ffffff"}
          />
          <YAxis tick={{ fill: "#ffffff" }} tickFormatter={DataFormater} color={cleanBg ? "#000" : "#ffffff"} />

          <Tooltip labelFormatter={formatXAxis} content={<CustomTooltip />} />
          <Area
            animationBegin={400}
            type="monotone"
            dataKey={yAxisDataKey}
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#gradient)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CardWithGraph;

