import { useState, useEffect } from "react";
import { apiInstance } from "../config/apiConfig";
import { DexMarketHistoryTypes } from "../utils/types";

export const useMarketHistory = ({ chainId, tokenAddress }: { chainId: number | string; tokenAddress: string }) => {
  const [data, setData] = useState<DexMarketHistoryTypes[]>([]);

  useEffect(() => {
    async function fetchMarketHistory() {
      const { data } = await apiInstance.get(`/market-history/${chainId}/${tokenAddress}`);

      let date = new Date().getTime() / 1000 - 30 * 24 * 60 * 60;

      const filteredData = data.history.data.filter((item: DexMarketHistoryTypes) => {
        return item.timestamp >= date;
      });

      console.log("filteredData", filteredData);

      setData(filteredData);
    }

    fetchMarketHistory();
  }, [chainId, tokenAddress]);

  return { data };
};
