import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Chat from "../../components/Chat/Chat";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import TextToImage from "../../components/TextToImage/TextToImage";
import Text2Video from "../../components/Text2Video/Text2Video";
import { useTabContextProvider } from "../../context/TabContext";
import { useStyles } from "./styles";
import Dashboard from "../Dashboard/Dashboard";
import Pool from "../Pool/Pool";
import pepe from "../../assets/images/pepe.png";
import { Box, Button, Drawer, useMediaQuery, IconButton } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { theme } from "../../utils/theme";
import MenuIcon from "@mui/icons-material/Menu";
const AskAnyAi = () => {
  const { classes } = useStyles();
  const { handleTabChange, tabValue, changeTab } = useTabContextProvider();
  const state = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (state.state?.tab) changeTab(state.state.tab);
    return;
  }, []);
  const toggleDrawer = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.wrapper}>
      {isMobile && (
        <div className={classes.headerContent}>
          {/* // <div className={classes.logo} > */}
          <img src={pepe} alt="Pepe Punk logo" />
          {/* // <Typography  variant="h4">Pepe Punks</Typography> */}
          {/* // </div> */}
          <span style={{ marginLeft: "auto" }}>
            <ConnectButton />
          </span>
          <IconButton onClick={() => toggleDrawer(true)} color="secondary">
            <MenuIcon />
          </IconButton>
        </div>
      )}

      <span className={classes.walletBtn}>
        <ConnectButton />
      </span>

      <div className={classes.root}>
        {!isMobile && (
          <CustomTabs
            tabs={["Image", "Chat", "Dashboard", "Farm"]}
            value={tabValue}
            onTabChange={handleTabChange}
            className={classes.tabs}
          />
        )}
        <Box
          className={classes.tabsContainer}
          sx={tabValue === 3 || tabValue === 4 ? { margin: "0% auto !important", maxWidth: "100% !important" } : {}}
        >
          {(tabValue === 0 || tabValue === 1) && <Typography variant="h3">Type your prompt here</Typography>}
          {tabValue === 0 && <TextToImage />}
          {tabValue === 1 && <Chat />}
          {/* {tabValue === 2 && <Text2Video />} */}
          {tabValue === 2 && <Dashboard />}
          {tabValue === 3 && <Pool />}
        </Box>
      </div>

      {isMobile && (
        <Drawer
          PaperProps={{
            className: classes.paperProps,
          }}
          anchor={"right"}
          open={isDrawerOpen}
          onClose={() => toggleDrawer(false)}
        >
          <CustomTabs
            tabs={["Image", "Chat", "Dashboard", "Farm"]}
            value={tabValue}
            onTabChange={handleTabChange}
            className={classes.tabs}
          />
        </Drawer>
      )}
    </div>
  );
};

export default AskAnyAi;
