import { makeStyles } from "../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    borderRight: '3px solid #00FFA3',
    maxWidth: '250px',
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
      maxWidth: '100%',
      width: "100%",
      // maxWidth: '400px',
      // padding: "0px 13px",
      borderRight: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px auto",
    },
  },
  mainContainer: {
    position: 'sticky',
    top: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '14px 0px',
    gap: '18px',
    color: '#ffffff',
    [theme.breakpoints.down("md")]: {
      width: "100%",
      // padding: "20px 10px",
      // maxWidth: "400px"
    },
    "& img": {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        // maxWidth: "131px"
      },
    }
  },
}));
