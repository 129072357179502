import { makeStyles } from "../../utils/theme";
import React from "react";
import { useCleanBgContext } from "../../context/CleanBgContext";
import { useCountdown } from "../../hooks/useCountdown";
import { keyframes } from "@mui/system";
// import DateTimeDisplay from './DateTimeDisplay';
// import { useCountdown } from './hooks/useCountdown';

const countdowAnimation = keyframes`
  0% {
    transform: scale(1);
  } 100% {
    transform: scale(1.05);
  }
`;

export const useStyles = makeStyles()((theme) => ({
  countdown: {
    animation: `${countdowAnimation} 1s ease-in-out infinite alternate`,
    padding: "10px 10px 10px 10px",
    position: "fixed",
    // width: "155px",
    bottom: 20,
    left: 20,
    flexDirection: "column",
    display: "flex",
    ":hover": {
      transform: "scale(1.1)",
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  countdowntext: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  time: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

const DateTimeDisplay = ({ value, type, isDanger }: any) => {
  const { classes } = useStyles();
  return (
    <div className={classes.time}>
      <div>
        {value}
        {type.substr(0, 1)}
      </div>
    </div>
  );
};

const ExpiredNotice = () => {
  return <div style={{ display: "none" }}></div>;
};

const ShowCounter = ({ days, hours, minutes, seconds }: any) => {
  const { cleanBg } = useCleanBgContext();
  const { classes } = useStyles();
  return (
    <div
      className={classes.countdown}
      style={
        cleanBg
          ? {
              color: "black",
              border: "2px solid #000",
            }
          : {
              border: "2px solid #fbc365",
              color: "#fbc365",
            }
      }
    >
      <div className={classes.countdowntext}>PRESALE</div>
      <div style={{ display: "flex", gap: "5px" }}>
        <DateTimeDisplay value={days} type={"Days"} />
        <DateTimeDisplay value={hours} type={"Hours"} />
        <DateTimeDisplay value={minutes} type={"Mins"} />
        <DateTimeDisplay value={seconds} type={"Seconds"} />
      </div>
    </div>
  );
};

const CountdownTimer = ({ date }: any) => {
  const [days, hours, minutes, seconds] = useCountdown(date);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
  }
};

export default CountdownTimer;
