import { ethers } from "ethers";
import { createContext, useContext, useMemo } from "react";
import { useMulticall } from "../hooks/useStaking";
import { CHUNK_SIZE } from "../utils";

interface initialStateType {
  balances?: any[];
  refetch: () => Promise<void>;
  stakedTokenContractAddresses?: `0x${string}`[];
  dashboardData: {
    pendingRewards: number;
    rewardDebt: number;
  };
}

const initialState: initialStateType = {
  balances: [],
  stakedTokenContractAddresses: [],
  refetch: async () => console.log("refetch"),
  dashboardData: {
    pendingRewards: 0,
    rewardDebt: 0,
  },
};

const BalancesContext = createContext(initialState);
export const useBalancesContext = () => useContext(BalancesContext);

interface BalancesContextProviderTypes {
  children: JSX.Element;
}

export const BalancesContextProvider: React.FC<BalancesContextProviderTypes> = ({ children }) => {
  const { balances, refetch, stakedTokenContractAddresses } = useMulticall();

  const dashboardData = useMemo(() => {
    let value = {
      pendingRewards: 0,
      rewardDebt: 0,
    };

    if (!balances || balances.length === 0)
      return {
        pendingRewards: 0,
        rewardDebt: 0,
      };

    for (let i = 0; i < balances.length; i += CHUNK_SIZE) {
      const poolBalances = balances.slice(i, i + CHUNK_SIZE);

      const rewardEarned = Number(ethers.utils.formatEther(poolBalances[1].rewardDebt).toString());

      const pendingReward = Number(ethers.utils.formatEther(poolBalances[2]).toString());

      value.rewardDebt += rewardEarned;
      value.pendingRewards += pendingReward;
    }

    return value;
  }, [balances]);

  return (
    <BalancesContext.Provider
      value={{
        stakedTokenContractAddresses,
        refetch,
        dashboardData,
        balances: balances,
      }}
    >
      {children}
    </BalancesContext.Provider>
  );
};
