import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import Button from "../../../components/button";
import FarmCardStyleWrapper from "./FarmCard.style";
import infoIcon from "../../../assets/images/icons/info-circle.svg";
import linkIcon from "../../../assets/images/icons/link_icon.svg";
import pepepunk from "../../../assets/images/icons/pepepunk.png";
import { useCleanBgContext } from "../../../context/CleanBgContext";

const FarmCard = ({ icon, pool, apy, tvl, ASKRequired, earned }) => {
  const [expand, setExpand] = useState(false);
  const { cleanBg } = useCleanBgContext();
  return (
    <FarmCardStyleWrapper
      cleanBg={cleanBg ? "#bdbdbd" : "#111111"}
      color={cleanBg ? "black" : "rgba(255, 255, 255, 0.69)"}
      onClick={() => setExpand(!expand)}
      className={`form_item_wrapper ${expand ? "active" : ""}`}
    >
      <ul className="farm_header">
        <li data-title="Pools">
          <img
            style={{
              height: "40px",
              width: "40px",
              zIndex: "2",
              border: "1px solid #00ffa3",
              backgroundColor: "#000000",
              borderRadius: "50%",
              padding: "3px",
            }}
            src={icon}
            alt="farm icon"
          />
          {pool !== "" && (
            <img
              style={{
                height: "40px",
                width: "40px",
                position: "relative",
                left: "-17px",
                zIndex: "1",
              }}
              src={pepepunk}
              alt="pepepunk icon"
            />
          )}
          {pool}
        </li>
        <li data-title="APY">{apy}</li>
        <li data-title="tvl">{tvl}</li>
        <li data-title="Required">{ASKRequired}</li>
        <li data-title="Earned">{earned}</li>
        {/* expand arrow */}
        <li className="expand_arrow" onClick={() => setExpand(!expand)}>
          <FiChevronDown />
          {/* <div style={{width: "30px", height: "30px", backgroundColor: "red"}}></div> */}
        </li>
      </ul>

      {/* content will show when expanded */}
      <div className="farm_content_wrapper">
        <div className="farm_content_list">
          <div className="farm_box_item farm_deposit">
            <label htmlFor="deposit">Deposit</label>

            <div className="input_box">
              <input type="text" placeholder="0.00" />
              <Button href="# " variant="blue" className="approve_btn">
                {" "}
                Approve{" "}
              </Button>
              <span className="deposite_amout_title">Max</span>
            </div>

            <h5>Your balance: 0 BNB</h5>
          </div>
          <div className="farm_box_item farm_withdraw">
            <label htmlFor="deposite">Withdraw</label>

            <div className="input_box">
              <input type="text" placeholder="0.00" />
              <Button href="# " variant="blue" className="approve_btn">
                {" "}
                Approve{" "}
              </Button>
              <span className="deposite_amout_title">Max</span>
            </div>

            <h5>Your balance: 0 BNB</h5>
          </div>
          <div className="farm_box_item farm_balance">
            <h4>Pending Rewards</h4>
            <div className="fram_wallet">
              <span>0 BNB</span>
              <Button href="# " variant="blue" className="farm_claim_btn">
                {" "}
                Claim{" "}
              </Button>
            </div>
          </div>
        </div>

        <div className="farm_bottom_info">
          <a href="#">
            Get {pool}
            <img src={linkIcon} alt="icon" />
          </a>
          <a href="#">
            View Contract
            <img src={linkIcon} alt="icon" />
          </a>
          <a href="#">
            Unstaking Fee
            <img src={infoIcon} alt="icon" />
          </a>
        </div>
      </div>
    </FarmCardStyleWrapper>
  );
};

export default FarmCard;
