import { makeStyles } from "../../utils/theme";
export const useStyles = makeStyles()((theme) => ({
  wrapper: {

    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    minHeight: "222px",
    marginTop: "21px",

  },
  root: {
    // minWidth: "70vw",
    width: "100%",
    // maxWidth: "90vw",
    // marginBottom: theme.spacing(3),
  },
  inputWrapper: {
    position: "sticky",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    margin: 0,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
  // inputFieldContainer: {
  // padding: "10px",
  // color: "black",
  // width: "90%",
  // background: "white",
  // border: `1px solid ${theme.palette.secondary.main}`,
  // borderRight: "0px",
  // backgroundColor: "#f1f1f1",
  // // borderTopLeftRadius: "5px",
  // borderRadius: "50px",
  // maxWidth: "1000px",
  // // borderBottomLeftRadius: "5px",
  // "@media (min-width: 2200px)": {
  //   fontSize: "1.5rem",
  //   justifyContent: "center",
  // },},
  input: {

    color: "#ffffff !important",
    padding: "6px 0px",
  },
  inputFieldContainer: {
    // padding: "10px",
    width: "100%",
    minHeight: "54px",
    background: "white",
    // border: `1px solid ${theme.palette.secondary.main}`,
    // borderRight: "0px",
    backgroundColor: "transparent",
    // borderTopLeftRadius: "5px",
    // maxWidth: "1000px",
    // borderRadius: "50px",
    "::placeholder": {
      // opacity: 1,
      fontFamily: "'Inter' !important",

      "@media (min-width: 2200px)": {
        fontSize: "2rem",
        fontWeight: 200
      },
    },
    "::-ms-input-placeholder": {
      color: "#ffffff",
    },
    "@media (min-width: 2200px)": {
      fontSize: "1.5rem",
      justifyContent: "center",
    },
    // borderBottomLeftRadius: "5px",
  },



  // btn: {
  // padding: "5px 40px",
  // // borderTopLeftRadius: "0px",
  // // borderBottomLeftRadius: "0px",
  // margin: ".5rem",
  // fontWeight: theme.typography.fontWeightBold,
  // [theme.breakpoints.down("sm")]: {
  //   alignSelf: "center",
  // },

  // },
  btn: {
    padding: "5px 5px",
    // borderTopLeftRadius: "15px",
    // borderBottomLeftRadius: "15px",
    color: theme.palette.secondary.main,
    // margin: ".5rem 0px",
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      alignSelf: "center",
    },
  },
  outputContainer: {
    // position: "relative",

    // display: "flex",
    // flexDirection: "column",
    overflowY: "auto",
    // borderRadius: "15px",
    padding: "0px 40px",
    // border: `1px solid ${theme.palette.secondary.main}`,
    marginBottom: "10px",
    background: "rgba(0,0,0, 0.7)",
    // width: "calc(100% - 80px)",
    // height: "50vh",

    "&::-webkit-scrollbar": {
      width: "7px",
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(92.03deg, #54FDF5 2.64%, #F8BB8A 100.21%)",
      borderRadius: "4px",
    },
  },
  outputWrapper: {
    marginTop: "auto",
  },
  output: {
    // height: "auto",
    padding: "40px 0px",
    position: "relative",
    borderBottom: "1px solid #FEBC89",

    "&:not(:last-of-type) *": {
      color: "rgba(255,255,255, 0.6)",
    },

    "&:last-of-type": {
      border: "none",
    },
  },
  inputDiv: {
    display: "flex",
    alignItems: "center",
    gap: "25px",
  },
  outputDiv: {
    display: "flex",
    alignItems: "flex-start",
    gap: "25px",
    whiteSpace: "pre-wrap",
  },
  outputImage: {
    marginBottom: "40px",
    position: "relative",
    top: "40px",
  },
}));
