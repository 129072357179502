import { BigNumber, ethers } from "ethers";
import { saveAs } from "file-saver";
import { apiInstance } from "../config/apiConfig";

export async function downloadImage(imageSrc: string, filename: string) {
  const image = await apiInstance.get(`/download-image?imageUrl=${imageSrc}`);
  saveAs(image.data.imageBase64, filename);
}

export function sleep(seconds: number) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export function truncateAddress(address?: string) {
  return address?.slice(0, 6) + "..." + address?.slice(address?.length - 3, address?.length);
}

export function handleRedirect(url: string) {
  window.open(url, "_blank");
}

export const formatEther = (value: BigNumber) => ethers.utils.formatEther(value);
export const parseEther = (value: string) => ethers.utils.parseEther(value);

export const apiType = {
  NONE: {
    endpoint: null,
    type: "Select Type",
  },
  TEXT2IMAGE: {
    endpoint: "/text2img",
    type: "Text to Image",
  },
  IMAGE2IMAGE: {
    endpoint: "/img2img",
    type: "Image to Image",
  },
  IMAGE_SEGMENTATION: {
    endpoint: "/image-segmentation",
    type: "Image Segmentation",
  },
  CHAT: {
    endpoint: "/chat",
    type: "Chat",
  },
};

export enum ContractNames {
  MockLPToken = "MockLPToken",
  StakingContract = "Staking",
  AskAnyAi = "AskAnyAi",
  AskAnyNFT = "AskAnyNFT",
}

export enum TabsEnum {
  Deposit = "Deposit",
  Withdraw = "Withdraw",
}

export const CHUNK_SIZE = 3;

export const TIME_TO_PRESALE = new Date(2023, 3, 28, 15, 0).getTime();
