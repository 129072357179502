import axios from "axios";

export const BASE_DOMAIN = process.env.REACT_APP_DEVELOPMENT_ENV
  ? "http://localhost:5000"
  : "https://pepe-vision-ai.herokuapp.com/";

export const BASE_URL = process.env.REACT_APP_DEVELOPMENT_ENV
  ? "http://localhost:5000/api"
  : "https://pepe-vision-ai.herokuapp.com/api";

export const apiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
