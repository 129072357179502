import { useState, useMemo, useEffect, useCallback } from "react";
import { useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import { truncateAddress } from "../utils";

export const useConnectWallet = () => {
  const { address, isConnected } = useAccount();
  const [chainSupported, setChainSupported] = useState(true);
  const { connectAsync, connectors } = useConnect();
  const { switchNetworkAsync, chains } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
  });
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();

  const connectWallet = useCallback(
    async (id: string) => {
      console.log("connectors", connectors);
      let connector = connectors.find((c) => c.id === id);
      await connectAsync({ connector });
    },
    [connectAsync, connectors]
  );

  const networkSwitch = useCallback(async () => {
    if (!switchNetworkAsync) return;
    await switchNetworkAsync(chains[0].id);
  }, [chains, switchNetworkAsync]);

  const truncatedAddress = useMemo(() => truncateAddress(address as string), [address]);

  useEffect(() => {
    if (chains.length !== 0 && !!chain && !chains.find((c) => c.id === chain?.id)) {
      setChainSupported(false);
    } else {
      setChainSupported(true);
    }
  }, [chain, chains]);

  return {
    disconnect,
    address,
    isConnected,
    chainSupported,
    connectWallet,
    networkSwitch,
    truncatedAddress,
  };
};
