import { Typography } from "@mui/material";
import { useTimer } from "../../../hooks/useTimer";

const Timer = ({ time, textType }: { time?: number; textType: "Images" | "Video" }) => {
  const { timeLeft, timeFinished } = useTimer(time);

  return (
    <div>
      {timeFinished ? (
        <Typography color={"text.primary"} textAlign="center">
          Processing... Please Wait
        </Typography>
      ) : (
        <Typography color={"text.primary"} textAlign="center">
          Your {textType} will be available after {timeLeft?.minutes}:{timeLeft?.seconds}
        </Typography>
      )}
    </div>
  );
};

export default Timer;
