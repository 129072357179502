import { RouterProvider } from "react-router-dom";
import "./App.css";
import { BalancesContextProvider } from "./context/BalancesContext";
import CleanBgContextProvider from "./context/CleanBgContext";
import { TabContextProvider } from "./context/TabContext";
import { WalletModalContextProvider } from "./context/WalletModalContext";
import { router } from "./Routes";

function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <CleanBgContextProvider>
        <BalancesContextProvider>
          <WalletModalContextProvider>
            <TabContextProvider>
              <RouterProvider router={router} />
            </TabContextProvider>
          </WalletModalContextProvider>
        </BalancesContextProvider>
      </CleanBgContextProvider>
    </div>
  );
}

export default App;
