import React, { memo } from "react";
import { useStyles } from "./styles";
// import cleanbg from "../../assets/images/cleanbg.png";
import ParticlesBackground from "./particlesBackground/particlesBackground";
import gradientbg from "../../assets/images/bg.png";
import bgImage from "../../assets/images/bg-image.png";
const BackgroundCanvas = ({ cleanBg = false }: { cleanBg?: boolean }) => {
  // const ref = React.useRef<HTMLCanvasElement>(null);
  const { classes } = useStyles();

  // const startAnimate = React.useCallback(() => {
  //   if (!ref.current) return;
  //   let c = ref.current;
  //   var ctx = c.getContext("2d");
  //   //making the canvas full screen
  //   c.height = window.innerHeight;
  //   c.width = window.innerWidth;
  //   var matrix: string | string[] = "01010101010101010";
  //   //converting the string into an array of single characters
  //   matrix = matrix.split("");

  //   var font_size = 16;
  //   var columns = c.width / font_size; //number of columns for the rain
  //   //an array of drops - one per column
  //   var drops: number[] = [];
  //   //x below is the x coordinate
  //   //1 = y co-ordinate of the drop(same for every drop initially)
  //   for (var x = 0; x < columns; x++) drops[x] = 1;
  //   //drawing the characters
  //   function draw() {
  //     if (!ctx) return;
  //     //Black BG for the canvas
  //     //translucent BG to show trail
  //     ctx.fillStyle = "rgba(0, 0, 0, 0.04)";
  //     ctx.fillRect(0, 0, c.width, c.height);

  //     ctx.fillStyle = "rgba(0, 255, 0, 0.3)"; //green text
  //     ctx.font = font_size + "px arial";
  //     //looping over drops
  //     for (var i = 0; i < drops.length; i++) {
  //       //a random chinese character to print
  //       var text = matrix[Math.floor(Math.random() * matrix.length)];
  //       //x = i*font_size, y = value of drops[i]*font_size
  //       ctx.fillText(text, i * font_size, drops[i] * font_size);

  //       //sending the drop back to the top randomly after it has crossed the screen
  //       //adding a randomness to the reset to make the drops scattered on the Y axis
  //       if (drops[i] * font_size > c.height && Math.random() > 0.975) drops[i] = 0;

  //       //incrementing Y coordinate
  //       drops[i]++;
  //     }
  //   }
  //   return draw;
  // }, [ref]);

  // React.useEffect(() => {
  //   if (!cleanBg) {
  //     let draw = startAnimate();
  //     let int = setInterval(draw!, 35);
  //     return () => {
  //       clearInterval(int);
  //     };
  //   }
  // }, [startAnimate, cleanBg]);

  return (
    <>
      {cleanBg ? (
        <div
          className={classes.canvas}
          style={{
            // backgroundColor: "#b4d5f9",
            // backgroundImage:
            // "radial-gradient(at 0% -20%, #b2d3f8 0, transparent 50%), radial-gradient(at 80% 90%, #a9cbf7 0, transparent 100%)",
            backgroundImage: `url(${gradientbg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
      ) : (
        <>
          <div
            className={classes.canvas}
            style={{
              backgroundColor: "black",
              // backgroundColor: "#b4d5f9",
              // backgroundImage:
              // "radial-gradient(at 0% -20%, #b2d3f8 0, transparent 50%), radial-gradient(at 80% 90%, #a9cbf7 0, transparent 100%)",
              backgroundImage: `url(${bgImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
          {/* <ParticlesBackground /> */}
        </>
      )}
    </>
  );
};

export default memo(BackgroundCanvas);
