import { Box, Button, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import imageToImage from "../../../assets/icons/image-to-image.png";
import microphone from "../../../assets/icons/microphone.png";
import negativePrompt from "../../../assets/icons/negative-prompt.png";
import segmentation from "../../../assets/icons/segmentation.png";
import { apiType } from "../../../utils";
import { models } from "../../../utils/models";
import { TextToImageInputTypes } from "../../../utils/types";
import DiffusionModelSelect from "../DiffusionModelSelect/DiffusionModelSelect";
import { useStyles } from "../styles";

const InputForm = ({
  handleImageGenerate,
  setError,
  loading,
  children,
  isModal,
  openPrompt,
  setOpenPrompt,
}: {
  handleImageGenerate: (inputData: TextToImageInputTypes, type: string) => Promise<void>;
  loading: boolean;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  children: any;
  time?: number;
  isModal?: boolean;
  openPrompt?: { status: boolean; imageUrl: string | null };
  setOpenPrompt: React.Dispatch<React.SetStateAction<{ status: boolean; imageUrl: string | null }>> | null;
}) => {
  const { classes } = useStyles();
  const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [inputData, setInputData] = useState<TextToImageInputTypes>({
    prompt: "",
    modelId: models[0].model_id,
    negativePrompt: "",
    initialImageUrl: "",
  });

  const [type, setType] = useState(apiType.TEXT2IMAGE.type);
  const [showNegativePrompt, setShowNegativePrompt] = useState(false);
  const [isListening, setIsListening] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>) => {
    setInputData((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSpeech = () => {
    if (!isListening) {
      SpeechRecognition.startListening({
        continuous: false,
      });
      setIsListening(true);
    } else {
      SpeechRecognition.stopListening();
      setInputData((prevValue) => ({
        ...prevValue,
        prompt: transcript,
      }));
      setIsListening(false);
      if (openPrompt?.status && !!openPrompt.imageUrl) {
        handleImageGenerate(
          {
            initialImageUrl: openPrompt.imageUrl,
            modelId: inputData.modelId,
            negativePrompt: inputData.negativePrompt,
            prompt: transcript,
          },
          apiType.IMAGE2IMAGE.endpoint
        );

        setOpenPrompt && setOpenPrompt({ status: false, imageUrl: null });

        return;
      }

      if (type === apiType.TEXT2IMAGE.type) {
        // if (setOpenPrompt !== null) setOpenPrompt(!openPrompt);
        return handleImageGenerate(
          {
            initialImageUrl: inputData.initialImageUrl,
            modelId: inputData.modelId,
            negativePrompt: inputData.negativePrompt,
            prompt: transcript,
          },
          apiType.TEXT2IMAGE.endpoint
        );
      } else if (type === apiType.IMAGE2IMAGE.type) {
        // if (setOpenPrompt !== null) setOpenPrompt(!openPrompt);
        return handleImageGenerate(
          {
            initialImageUrl: inputData.initialImageUrl,
            modelId: inputData.modelId,
            negativePrompt: inputData.negativePrompt,
            prompt: transcript,
          },
          apiType.IMAGE2IMAGE.endpoint
        );
      } else if (type === apiType.IMAGE_SEGMENTATION.type) {
        return handleImageGenerate(
          {
            initialImageUrl: inputData.initialImageUrl,
            modelId: inputData.modelId,
            negativePrompt: inputData.negativePrompt,
            prompt: transcript,
          },
          apiType.IMAGE_SEGMENTATION.endpoint
        );
      }
    }
  };

  const handleChange = () => {
    // const { value } = e.target;
    if (type === apiType.TEXT2IMAGE.type || type === apiType.IMAGE_SEGMENTATION.type) {
      setType(apiType.IMAGE2IMAGE.type);
      setInputData((prev) => ({
        ...prev,
        initialImageUrl: "",
      }));
      return;
    } else {
      setType(apiType.TEXT2IMAGE.type);
      return;
    }
  };

  const handleImageSegmentationTab = () => {
    if (type === apiType.TEXT2IMAGE.type || type === apiType.IMAGE2IMAGE.type) {
      setType(apiType.IMAGE_SEGMENTATION.type);
      setInputData((prev) => ({
        ...prev,
        initialImageUrl: "",
      }));
      return;
    } else {
      setType(apiType.TEXT2IMAGE.type);
      return;
    }
  };

  return (
    <div className={classes.container}>
      <DiffusionModelSelect setInputData={setInputData}>
        <div style={{ display: "flex", gap: "10px" }}>
          {!isModal && (
            <>
              <img
                title="Negative Prompt (optional) "
                className={classes.negativePromptBtn}
                onClick={() => setShowNegativePrompt((prev) => !prev)}
                src={negativePrompt}
                alt="negative-text"
              />
              <img
                title="Image to Image"
                style={{ height: "33px", cursor: "pointer" }}
                onClick={handleChange}
                src={imageToImage}
                alt="change-to-image-to-image"
              />
              {/* <img
                title="Image Segmentation"
                style={{ height: "33px", cursor: "pointer" }}
                onClick={handleImageSegmentationTab}
                src={segmentation}
                alt="change-to-image-segmentation"
              /> */}
              
            </>
          )}
          {browserSupportsSpeechRecognition && (
            <img
              src={microphone}
              className={isListening ? classes.animation : ""}
              onClick={handleSpeech}
              style={{ cursor: "pointer", marginLeft: "-4px" }}
              alt="mic"
              height="30px"
            />
          )}
        </div>
      </DiffusionModelSelect>

      <form
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          if (type === apiType.NONE.type) {
            return setError("Please select type first");
          }

          if (type === apiType.TEXT2IMAGE.type) {
            // if (setOpenPrompt !== null) setOpenPrompt(!openPrompt);
            return handleImageGenerate(inputData, apiType.TEXT2IMAGE.endpoint);
          } else if (type === apiType.IMAGE2IMAGE.type) {
            // if (setOpenPrompt !== null) setOpenPrompt(!openPrompt);
            return handleImageGenerate(inputData, apiType.IMAGE2IMAGE.endpoint);
          } else if (type === apiType.IMAGE_SEGMENTATION.type) {
            return handleImageGenerate(inputData, apiType.IMAGE_SEGMENTATION.endpoint);
          }
        }}
      >
        <Box className={classes.boxContainer}>
          <div className={classes.wrapper}>
            <TextField
              name="prompt"
              placeholder="What are you looking for?"
              variant="standard"
              value={inputData.prompt}
              onChange={handleInputChange}
              className={classes.inputFieldContainer}
              InputProps={{
                endAdornment: (
                  <Button
                    variant="text"
                    color="secondary"
                    className={classes.btn}
                    disabled={loading}
                    onClick={() => {
                      if (type === apiType.NONE.type) {
                        return setError("Please select type first");
                      }

                      if (openPrompt?.status && !!openPrompt.imageUrl) {
                        handleImageGenerate(
                          {
                            initialImageUrl: openPrompt.imageUrl,
                            modelId: inputData.modelId,
                            negativePrompt: inputData.negativePrompt,
                            prompt: inputData.prompt,
                          },
                          apiType.IMAGE2IMAGE.endpoint
                        );

                        setOpenPrompt &&
                          setOpenPrompt((prev) => ({
                            status: false,
                            imageUrl: null,
                          }));

                        return;
                      }

                      if (type === apiType.TEXT2IMAGE.type) {
                        // if (setOpenPrompt !== null) setOpenPrompt(!openPrompt);
                        return handleImageGenerate(inputData, apiType.TEXT2IMAGE.endpoint);
                      } else if (type === apiType.IMAGE2IMAGE.type) {
                        // if (setOpenPrompt !== null) setOpenPrompt(() => false);
                        return handleImageGenerate(inputData, apiType.IMAGE2IMAGE.endpoint);
                      } else if (type === apiType.IMAGE_SEGMENTATION.type) {
                        return handleImageGenerate(inputData, apiType.IMAGE_SEGMENTATION.endpoint);
                      }
                    }}
                  >
                    {loading ? "Loading..." : "Generate"}
                  </Button>
                ),
              }}
            />

            {showNegativePrompt && (
              <TextField
                name="negativePrompt"
                value={inputData.negativePrompt}
                onChange={handleInputChange}
                multiline
                placeholder="Please let me know if there is anything you don't want to be included in your image..."
                variant="standard"
                className={classes.inputFieldContainer2}
              />
            )}

            {(type === apiType.IMAGE2IMAGE.type || type === apiType.IMAGE_SEGMENTATION.type) && (
              <TextField
                name="initialImageUrl"
                value={inputData.initialImageUrl}
                onChange={handleInputChange}
                placeholder={type === apiType.IMAGE_SEGMENTATION.type ? "Image URL for segmentation" : "Image URL"}
                variant="standard"
                className={classes.inputFieldContainer2}
              />
            )}
          </div>
        </Box>
      </form>
      {children}
    </div>
  );
};

export default InputForm;
