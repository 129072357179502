import React, { createContext, useContext, useState } from "react";

const initialState = {
  openConnectionModal: false,
  handleOpenConnectionModal: (open: boolean) => {},
};

export const WalletModalContext = createContext(initialState);

export const useWalletModalContext = () => useContext(WalletModalContext);

export const WalletModalContextProvider = ({ children }: { children: JSX.Element }) => {
  const [openConnectionModal, setOpenConnectionModal] = useState(false);

  const handleOpenConnectionModal = (open: boolean) => {
    setOpenConnectionModal(open);
  };

  return (
    <WalletModalContext.Provider
      value={{
        openConnectionModal,
        handleOpenConnectionModal,
      }}
    >
      {children}
    </WalletModalContext.Provider>
  );
};
