import { Skeleton, Typography } from "@mui/material";
import binanceLink from "../../assets/icons/binanceLink.png";
import cakeLink from "../../assets/icons/cakeLink.png";
import cake_clean from "../../assets/icons/cake_clean.png";
import coinmarketcapLink from "../../assets/icons/cmcLink.png";
import cmc_clean from "../../assets/icons/cmc_clean.png";
import coingeckoLink from "../../assets/icons/coingecko.png";
import coingeckoDark from "../../assets/icons/coingeckodark.png";
import huobiLink from "../../assets/icons/huobiLink.png";
import kucoinLink from "../../assets/icons/kucoinLink.png";
import mexcLink from "../../assets/icons/mexcLink.png";
import mexc_dark from "../../assets/icons/mexc_dark.png";
import BackgroundCanvas from "../../components/BackgroundCanvas/BackgroundCanvas";
import Card from "../../components/Card/Card";
import CardWithGraph from "../../components/CardWithGraph/CardWithGraph";
import Header from "../../components/Header/Header";
import { useBalancesContext } from "../../context/BalancesContext";
import { useCleanBgContext } from "../../context/CleanBgContext";
import { useMarketHistory } from "../../hooks/useMarketHistory";
import { useStyles } from "./styles";

const Dashboard = () => {
  const { classes } = useStyles();

  const { cleanBg } = useCleanBgContext();
  const { dashboardData } = useBalancesContext();

  const { data } = useMarketHistory({
    chainId: 56,
    tokenAddress: "0x25fd42d82d5c238ee7aa277261aa6ca5bdfe5cd4",
  });

  const imagesLinks = [
    cleanBg ? cmc_clean : coinmarketcapLink,
    cleanBg ? coingeckoLink : coingeckoDark,
    cleanBg ? cake_clean : cakeLink,
    cleanBg ? mexcLink : mexc_dark,
    binanceLink,
    huobiLink,
    kucoinLink,
  ];

  return (
    <>
      {/* <BackgroundCanvas cleanBg={cleanBg} /> */}
      <div className={classes.mainContainer}>
        {/* <Header /> */}

        <div className={classes.dashboard}>
          <div className={classes.graphsContainer}>
            <div className={classes.cardWrapper}>
              <div className={classes.cardsContainer}>
                <Typography
                  style={{ alignSelf: "center" }}
                  variant="h4"
                  color={cleanBg ? "text.secondary" : "text.primary"}
                >
                  Overview
                </Typography>
                <div className={classes.cardHead}>
                  <Card title="Farming APY" value={"0.0"} />
                  <Card title="Price" value={"0.0"} />
                  <Card title="Liquidity" value={"0.0"} />
                  <Card title="Volume" value={dashboardData.pendingRewards.toFixed(1)} />
                  <Card title="Circulating Supply" value={dashboardData.rewardDebt.toFixed(1)} />
                  <Card title="Pending Rewards" value={"0.0"} />
                </div>
              </div>
            </div>
            <div className={classes.graphWrapper}>
              <div className={classes.graphContainer}>
                {data.length !== 0 ? (
                  <CardWithGraph data={data} title="Liquidity" xAxisDataKey="timestamp" yAxisDataKey="liquidity_usd" />
                ) : (
                  <Skeleton
                    width={"100%"}
                    variant="rectangular"
                    height="275px"
                    sx={cleanBg ? { backgroundColor: "rgba(0,0,0,0.3)" } : {}}
                  />
                )}
              </div>
              <div className={classes.graphContainer}>
                {data.length !== 0 ? (
                  <CardWithGraph data={data} title="Volume" xAxisDataKey="timestamp" yAxisDataKey="volume24h_usd" />
                ) : (
                  <Skeleton
                    width={"100%"}
                    variant="rounded"
                    height="275px"
                    sx={cleanBg ? { backgroundColor: "rgba(0,0,0,0.3)" } : {}}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "3%",
          }}
        >
          <div
            style={
              cleanBg
                ? { alignSelf: "center", fontSize: "20px" }
                : { alignSelf: "center", fontSize: "20px", color: "white" }
            }
          >
            Relevant Links
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "3%",
            }}
          >
            {imagesLinks.map((link) => {
              return <img alt="link" key={link} src={link} className={classes.imageLink} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
