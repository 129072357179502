import { makeStyles } from "../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  canvas: {
    position: "fixed",
    zIndex: -100,
    width: "100vw",
    height: "100vh",
  },
}));
