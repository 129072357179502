import { useState } from "react";
import BackgroundCanvas from "../../components/BackgroundCanvas/BackgroundCanvas";
import Header from "../../components/Header/Header";
import { useCleanBgContext } from "../../context/CleanBgContext";
import { useStyles } from "./styles";
import { useBalancesContext } from "../../context/BalancesContext";
import { CHUNK_SIZE } from "../../utils";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Farm from "./Farm";

const Pool = () => {
  const { classes } = useStyles();
  const { cleanBg } = useCleanBgContext();
  const { balances } = useBalancesContext();
  const [isCore, setIsCore] = useState(true);

  return (
    <>
      {/* <BackgroundCanvas cleanBg={cleanBg} /> */}
      <div className={classes.mainContainer}>
        {/* <Header /> */}
        <div className={classes.poolContainer} style={{ borderRadius: "15px" }}>
          <Farm isCore={isCore} setIsCore={setIsCore}/>
          {/* <div style={{ display: "flex", justifyContent: "center", paddingBottom: "2rem" }}>
            <Stack style={{ marginTop: "10px" }} direction="row" spacing={1} alignItems="center">
              <Typography variant="h5" color={cleanBg ? "text.secondary" : "text.primary"}>
                Core
              </Typography>
              <Switch className={classes.switch} onChange={() => setIsCore(!isCore)} color="secondary" />
              <Typography variant="h5" color={cleanBg ? "text.secondary" : "text.primary"}>
                Alts
              </Typography>
            </Stack>
          </div> */}
          {/* <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#222222", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ padding: "20px 35px", color: "white", borderRight: "1px solid white" }}>ACTIVE</div>
              <div style={{ padding: "20px 35px", color: "white", borderRight: "1px solid white" }}>INACTIVE</div>
              <Stack direction="row" spacing={1} alignItems="center">
                <Switch className={classes.switch} onChange={() => setIsCore(!isCore)} color="secondary" />
                <Typography variant="h6" color={cleanBg ? "text.secondary" : "text.primary"}>
                  Alts
                </Typography>
              </Stack>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ color: "white", padding: "20px 25px", borderLeft: "1px solid white" }}>All Access</div>
            </div>
          </div> */}

          {/* <div style={{ display: "flex", padding: "3rem 25px 25px 25px", justifyContent: "space-between", alignItems: "center" }}>
            {titleNames.map((title, idx) => {
              return (
                <div key={idx} className={title === "Earned" ? classes.titleContainerEarned : classes.titleContainer}>
                <div style={{ display: "flex", gap: "2px" }}>
                  <div style={title === "Pool" ? {width: "100%"} : {}} className={classes.title}>{title}</div>
                  <img title={showTitle(title)} src={info} alt="info" className={classes.infoTitle} />
                  </div>
                </div>
              );
            })}
          </div> */}

          {/* <div>
            {pools.map((pool, i) => (
              <PoolCard
                key={i}
                poolId={pool.poolId}
                name={pool.name}
                image={pool.image}
                required={pool.required}
                balances={balances?.slice(i * CHUNK_SIZE, i * CHUNK_SIZE + CHUNK_SIZE)}
              />
            ))}
          </div> */}

          {/* {isCore ? (
            <>
              {pools.map((pool, i) => (
                <PoolCard
                  key={i}
                  poolId={pool.poolId}
                  name={pool.name}
                  image={pool.image}
                  required={pool.required}
                  balances={balances?.slice(i * CHUNK_SIZE, i * CHUNK_SIZE + CHUNK_SIZE)}
                />
              ))}
            </>
          ) : (
            <>
              {altPools.map((pool, i) => (
                <PoolCard
                  key={i}
                  poolId={pool.poolId}
                  name={pool.name}
                  image={pool.image}
                  required={pool.required}
                  balances={balances?.slice(i * CHUNK_SIZE, i * CHUNK_SIZE + CHUNK_SIZE)}
                />
              ))}
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Pool;

