import { Typography } from "@mui/material";
import React from "react";
import { useConnectWallet } from "../../hooks/useConnectWallet";
import { useStyles } from "./styles";
import image from "../../assets/images/pepe.png";
interface CardProps {
  title: string;
  value: string | number;
}

const Card: React.FC<CardProps> = ({ title, value }) => {
  const { classes } = useStyles();
  const { isConnected, chainSupported } = useConnectWallet();

  return (
    <div className={classes.root}>
      <img alt="logo" src={image} style={{ height: "50px" }} />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "60%" }}>
        <Typography color="text.primary" sx={{ marginBottom: 1,fontSize:"1.0rem" }}>
          {title}
        </Typography>
        <Typography color="text.primary" fontWeight={"bold"}>
          {!isConnected ? "Connect Wallet" : !chainSupported ? "Switch Network" : value}
        </Typography>
      </div>
    </div>
  );
};

export default Card;
