import { makeStyles } from "../../utils/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {},
  switch: {
    width: 66,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        transform: "translateX(30px)",
      },
    },
    "& .MuiSwitch-thumb": {
      width: 30,
      height: 30,
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
    },
  },
  mainContainer: {
    // padding: "5px 30px 30px 30px",
    width: "100%",

    // minWidth: "400px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#000000cc",
    paddingTop: "20px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "15px",
    paddingLeft: "40px",
    paddingRight: "25px",
    marginBottom: "5px",
    [theme.breakpoints.down("md")]: {
      // paddingLeft: "20px",
      // width: "100%"
    },
  },
  titleContainer: {
    display: "flex",
    // justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
    marginBottom: "20px",
    fontSize: "20px",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  titleContainerEarned: {
    display: "flex",
    position: "relative",
    flex: 1.2,
    alignItems: "center",
    marginBottom: "20px",
    fontSize: "20px",
    flexDirection: "column",
    left: "2%",
    [theme.breakpoints.up("lg")]: {
      flex: 1.2,
    },
    [theme.breakpoints.up("xl")]: {
      flex: 1.1,
      left: ".5%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      // flex: 1.2,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      // flex: 1.3,
      left: "0%"
    },
  },
  infoTitle: {
    height: "15px", cursor: "help",
    [theme.breakpoints.down("sm")]: {
      height: "10px"
    }
  },
  title: {
    color: "white",
    alignItems: "center",
    marginBottom: "20px",
    fontSize: "20px",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  poolContainer: {
    margin: "80px 100px 0px 100px",
    paddingRight: "15px",
    paddingLeft: "15px",
    [theme.breakpoints.down("md")]: {
      // marginTop: "40px",
    },
    "@media (max-width: 720px)": {
      // margin: "10px 20px 20px 10px",
      marginRight: "10px"
    },
  },
}));
