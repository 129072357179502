import { CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useTextToImageGenerator } from "../../hooks/useTextToImageGenerator";
import { apiType, downloadImage, TIME_TO_PRESALE } from "../../utils";
import { TextToImageInputTypes } from "../../utils/types";
import CountdownTimer from "../Countdown/Countdown";
import ImageModal from "./ImageModal";
import InputForm from "./InputForm/InputForm";
import { useStyles } from "./styles";
import Timer from "./Timer/Timer";
import "react-toastify/dist/ReactToastify.css";

const TextToImage = () => {
  const { generate, imagesData, loading, eta, processing, cannotFetch, error, setError } = useTextToImageGenerator();

  const { classes } = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleImageGenerate = async (inputData: TextToImageInputTypes, type: string) => {
    await generate(inputData, type);
    setIsOpen(true);
  };

  useEffect(() => {
    if (error) {
      setIsOpen(false);
    }
  }, [error, cannotFetch]);

  return (
    <div className={classes.textToImageWrapper}>
      <ToastContainer />
      <div className={classes.root}>
        <InputForm
          handleImageGenerate={handleImageGenerate}
          loading={loading}
          setError={setError}
          time={eta}
          setOpenPrompt={null}
        >
          {!!error && (
            <Typography color="text.primary" textAlign="center">
              {error}
            </Typography>
          )}
          {processing && <Timer time={eta} textType="Images" />}
          {cannotFetch && (
            <Typography color="text.primary" textAlign="center">
              Cannot generate images for the time being... Please try again later..
            </Typography>
          )}
        </InputForm>
      </div>

      <div className={classes.imagesContainer}>
        {loading && (
          <CircularProgress
            style={{
              zIndex: 1000,
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            color="primary"
          />
        )}
        <ImageModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          classes={classes}
          imagesData={imagesData}
          error={error}
          processing={processing}
          eta={eta}
          setError={setError}
          cannotFetch={cannotFetch}
          // setShowDownloadBtnFor={setShowDownloadBtnFor}
          // showDownloadBtnFor={showDownloadBtnFor}
          downloadImage={downloadImage}
          loading={loading}
          handleImageGenerate={handleImageGenerate}
          apiType={apiType}
        />
      </div>
      <CountdownTimer date={TIME_TO_PRESALE} />
    </div>
  );
};

export default TextToImage;
